import React, { Component, Fragment } from 'react';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

//import { WingDecal } from '../Components'
import { TracksDecal} from '../Components'
import { history } from '../_helpers';
import { alertActions } from '../actions'

// Pages
import { KeyConformation, UrlConformationPage, HomePage, LoginPage, TakingQuizPage, SignupPage, UserPage, ConfirmPage, AdminPage, OverviewPage, AddPage, EditPage, AddQuizPage, RecoveryPage, ProfilePage } from '../Pages';

// React Bootstrap Components
import Alert from 'react-bootstrap/Alert'
import { NavBar } from '../Components';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      this.props.clearAlerts();
    });
  }

  render() {
    const { alert } = this.props;

    return (<Fragment>
      {alert.message &&
        <Alert show={alert.message} variant={alert.type} className={`Dash fade-10 z-2`} onClick={() => this.props.clearAlerts()}>{alert.message}</Alert>
      }
      <NavBar />
      {/* 
        DO NOT USE PATHS 
          - api/*
          - media/*
        the server is using them
      */}
      <center className='site-container'>
        {/*<WingDecal /> */}
        <TracksDecal />
        <BrowserRouter history={history} >
          <Routes>
            <Route index element={<HomePage />} />

            <Route path="admin" >
              <Route index element={<AdminPage />} />
              <Route path="view" element={<OverviewPage />} />
              <Route path="add" element={<AddPage />} />
              <Route path="addQuiz" element={<AddQuizPage />} />
              <Route path="edit" element={<EditPage />} />
              <Route path="KeyConformation/:urlToken" element={<KeyConformationWrapper />} />
              <Route path="*" element={<Navigate to="admin" />} />
            </Route>
            <Route path="user" >
              <Route index element={<UserPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="quiz" element={<TakingQuizPage />} />
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route path="addAttempt/:id" element={<RetreiveId />} />
            <Route path="signin" element={<LoginPage />} />
            <Route path="recovery" element={<RecoveryPage />} />
            <Route path="reset" element={<RecoveryPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="confirm" element={<ConfirmPage />} />
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
        </BrowserRouter>
      </center>
    </Fragment>);
  }
}

function RetreiveId() {
  let { id } = useParams();
  return <UrlConformationPage id={id} />
}
function KeyConformationWrapper() {
  let { urlToken } = useParams();
  return <KeyConformation urlToken={urlToken} />
}
function mapState(state) {
  const { alert } = state
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);