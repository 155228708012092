import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }
        this.login = this.login.bind(this);
    }

    login(e) {
        e.preventDefault()
        const { password, email } = this.state;
        this.props.signin(email, password)
    }

    render() {
        const { clearance } = this.props.session;
        if (clearance !== "unknown")
            userActions.reroute("user")

        return (<Fragment>
            <form className='med-container' onSubmit={this.login}>
                <p className="blackText">Please sign in to your Motor Pool Services Certification account</p>
                <center className='fit-lrg'>
                    <div className='size-lrg blocky'>
                        <label htmlFor="inputEmail" >Email</label>
                        <input className='text' type="email" onChange={(e) => this.setState({ email: e.target.value })} required />
                        <div className='relative'>
                            <label htmlFor="inputEmail">Password</label>
                            <input className='text forgotpas-pad ' type="password" onChange={(e) => this.setState({ password: e.target.value })} required />
                            <a className='forgotpas' href="recovery">Forgot Password</a>
                            <a className='forgotpas registerlink' href="signup">Register Account</a>
                        </div>
                    </div>
                </center>
                <br />
                <center>
                    <button className="size-sml">Submit</button>
                </center>
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    signin: userActions.signin
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };