import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../actions';
import { Offcanvas, Navbar, Nav, Container } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa'


class NavBar extends Component {
    render() {
        const { token, clearance } = this.props;
        return (<Fragment>
            <Navbar key={false} expand={false} className="mb-3 sticky-top z-1 p-0">
                <Container fluid className="navBackground">
                    <Navbar.Brand href="/" ><h1 className='navTitle'>Motor Pool Services</h1></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} className="navIcon" style={{}}>
                        <FaBars className='nav-icon pb-3'/>
                    </Navbar.Toggle>
                    
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${false}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
                                Motor Pool Services
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {token ? <Fragment> {/* user is signed in */}
                                    <Nav.Link href="/user/profile">View Profile</Nav.Link>
                                    <Nav.Link href="/user">View Quizzes</Nav.Link>
                                    {clearance === 'admin' && <Nav.Link href="/admin/view">Admin</Nav.Link>}
                                    <Nav.Link href="/" onClick={this.props.logout}>Sign Out</Nav.Link>
                                </Fragment> : <Fragment> {/* newcomer */}
                                    <Nav.Link href="/signin">Log In</Nav.Link>
                                    <Nav.Link href="/signup">Create Account</Nav.Link>
                                </Fragment>}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </Fragment >);
    }
}

function mapState(state) {
    const { token, clearance } = state.session;
    return { token, clearance };
}

const actionCreators = {
    logout: userActions.logout
};

const connectedNavBar = connect(mapState, actionCreators)(NavBar);
export { connectedNavBar as NavBar };