import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';
import { QuestionPreview } from '../../Components';
import { AiFillEye } from 'react-icons/ai';
import ArrowDown from '../../images/ArrowDown.svg';
import ArrowUp from '../../images/ArrowUp.svg';

class AddQuizPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            questionsIds: [],
            questionsPerQuiz: -1,
            scrambleQuestions: false,
            cost: 0,
            keywords: [],
            discount: 0,
            query: "",
            viewingQuestion: "",
            Qattempt: 1,
            failAt: 0,
            QuestAttempt: 2,
            RedoIncrese: false,
            passing: 70,
            description: ''
        }
        this.filterQuestions = this.filterQuestions.bind(this)
        this.findQuestions = this.findQuestions.bind(this)
        this.removeQuestion = this.removeQuestion.bind(this)
        this.createQuiz = this.createQuiz.bind(this)
    }

    componentDidMount() {
        if (this.props.session.clearance != "admin")
            userActions.reroute("admin")
    }

    removeQuestion(e) {
        const { questionsIds } = this.state
        questionsIds.splice(e, 1)
        this.setState({ questionsIds: questionsIds })
    }

    filterQuestions() {
        const { query, questionsIds } = this.state
        if (!query) return
        try {
            const { deleteQueue } = this.state
            const { session } = this.props;
            const { questions } = this.props.session
            let test = new RegExp(query, "i")
            let filteredQuestions = questions.filter(q => (test.test(q.question) || q.tags.includes(query)) && !questionsIds.includes(q._id) )

            return <Fragment>{filteredQuestions.map((f, index) => {
                return <Fragment>
                    <div className='uline'>
                        <div className="inline gap choice spc-between mb-4 align-items-center">
                            <div className="quizText d-flex me-auto">
                                <p className="m-0 blackText">{f.question}</p>
                            </div>
                            <button type="button" className="createButtons d-flex" id={f._id} name="action"
                                onClick={(e) => this.viewQuestion(f._id)} value={f._id}>
                                <p className="fs-5 m-0 align-self-center"> View </p>
                            </button>
                            <button type="button" className="quiz" name="delete"
                                onClick={(e) => this.setState({ questionsIds: [...questionsIds, e.target.value] })} value={f._id}>
                                Add
                            </button>
                        </div >
                        {f.tags.map((tag, index) => (
                            <div className='tag '>
                                <div key={index}>{tag}</div>
                            </div>
                        ))}
                    </div>
                </Fragment>

            })}</Fragment>
        } catch { return }
    }

    viewQuestion(e) {
        this.setState({ viewingQuestion: e })
    }

    findQuestions() {
        const { questionsIds } = this.state;
        if (!questionsIds.length) return;

        const { questions } = this.props.session
        var search = JSON.parse(JSON.stringify(questionsIds))
        let hashTable = JSON.parse(`{${search.map((s, index) => `"${s}":${index}`).join(",")}}`)
        search = new Set(search)
        let _questions = JSON.parse(JSON.stringify(questionsIds))

        for (var i = 0; i < questions.length; i++) {
            if (!search.size) break;
            if (search.has(questions[i]._id)) {
                _questions[hashTable[questions[i]._id]] = questions[i]
                search.delete(questions[i]._id)
            }
        }

        return _questions.map((q, index) => {
            return ( <Fragment>
                <div className='uline'>
                    <div className="inline gap choice spc-between mb-4 align-items-center">
                        <div>
                            <button type="button" className="arrow-btn d-flex" id={q._id} name="action"
                                onClick={(e) => this.moveQuestion(index, -1)}>
                                <img src={ArrowUp} className="arrow align-self-center" value={index}/>
                            </button>
                            <button type="button" className="arrow-btn d-flex" id={q._id} name="action"
                                onClick={(e) => this.moveQuestion(index, 1)}>
                                <img src={ArrowDown} className="arrow align-self-center" value={index}/>
                            </button>
                        </div>
                        <div className="quizText d-flex me-auto">
                            <p className="m-0">Question #{index + 1}: {q.question}</p>
                        </div>
                        <button type="button" className="createButtons d-flex"
                            onClick={(e) => this.viewQuestion(q._id)} value={q._id}>
                        <p className="fs-5 m-0 align-self-center"> View </p>
                        </button>
                        <button type="button" className="quiz"
                            onClick={(e) => this.removeQuestion(e.target.value)} value={index}>
                            Remove
                        </button>
                    </div>
                    {q.tags.map((tag, index) => (
                        <div className='tag '>
                            <div key={index}>{tag}</div>
                        </div>
                    ))}
                </div>
            </Fragment>
            )
        })
    }

    addAllFlitered() {
        const { query, questionsIds } = this.state
        if (!query) return
        const { questions } = this.props.session

        let test = new RegExp(query, "i")
        const filtered = questions.filter(q => (test.test(q.question) || q.tags.includes(query)) && !questionsIds.includes(q._id)).map(i => i._id)
        this.setState({ questionsIds: [...questionsIds, ...filtered] })
    }

    createQuiz() {
        const { name, questionsIds, questionsPerQuiz, scrambleQuestions, cost, discount, Qattempt, failAt, QuestAttempt, RedoIncrese, description, passing } = this.state;

        const { token } = this.props.session;

        this.props.createQuiz(token, {
            name, questionsIds, questionsPerQuiz, scrambleQuestions, cost, discount, description,
            quitQuiz: failAt,
            quizAttempts: Qattempt,
            questionAttempts: QuestAttempt,
            nthAttemptImprove: RedoIncrese,
            passingPercent: passing
        })
    }

    moveQuestion(qIndex, direction) {
        const { questionsIds } = this.state;
        if(direction > 0) {
            if(qIndex + 1 < questionsIds.length) {
                [questionsIds[qIndex + 1], questionsIds[qIndex]] = [questionsIds[qIndex], questionsIds[qIndex + 1]];
            }
        } else {
            if(qIndex - 1 > -1) {
                [questionsIds[qIndex - 1], questionsIds[qIndex]] = [questionsIds[qIndex], questionsIds[qIndex - 1]];
            }
        }
        this.setState({ questionsIds: questionsIds})
    }

    // this function is here so the quiz is not submitted when they hit enter in the search field
    stop = (e) => e.preventDefault()

    render() {
        const { discount, cost, scrambleQuestions, questionsPerQuiz, viewingQuestion, questionsIds, failAt, Qattempt, QuestAttempt, RedoIncrese, description, passing } = this.state;
        const { questions } = this.props.session;
        return (<Fragment>
            {viewingQuestion && <div className='question-overlay'>
                <div className='question-overlay-out' onClick={() => this.setState({ viewingQuestion: "" })}></div>
                <QuestionPreview question={questions.filter(q => q._id == viewingQuestion)[0]} />
            </div>}

            <form className='creation' onSubmit={this.stop}>
                <div className="lrg-container contain border border-2 border-black">
                    <p className="blackText">Quiz Name</p>
                    <input className="form-control" id="name" placeholder='Quiz name' required autoComplete="off" onChange={(e) => this.setState({ name: e.target.value })} />
                </div>

                <div className="lrg-container contain border border-2 border-black">
                    <p>Quiz Questions</p>

                    <div className='flex-form gap'>
                        {this.findQuestions()}
                    </div>

                    <br />

                    <div>
                        <h5 className="blackText search-question">Search for Existing Questions.</h5>
                        <div className='flex-form gap'>
                            <div className=' inline gap choice spc-between'>
                                <div className='question-search inline gap'>
                                    <div className='me-auto questionText'>
                                        <input className="form-control" id="query" autoComplete="off" placeholder='$ to view all' onChange={(e) => this.setState({ query: e.target.value })} />
                                    </div>
                                    <button type="button" className="quiz"
                                        onClick={() => this.addAllFlitered()} >
                                        Add All
                                    </button>
                                </div>
                            </div>
                            {this.filterQuestions()}
                        </div>
                    </div>
                </div>

                <div className="lrg-container contain border border-2 border-black">
                    <p>Quiz Certificate Description</p>
                    <textarea className="form-control" rows={5} autoComplete="off" value={description} onChange={(e) => this.setState({ description: e.target.value })} />
                </div>

                <div className="lrg-container contain border border-2 border-black">
                    <p>Other Options</p>
                    <div className="flex-from gap">
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">Shuffle Questions</div>
                                <div className="col-2 align-left">
                                    <input type="checkbox" className="checkbox-square" autoComplete="off" checked={scrambleQuestions}
                                        onChange={(e) => this.setState({ scrambleQuestions: e.target.checked })} />
                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    Cost: <strong>{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    }).format(cost)}</strong>
                                </div>
                                <div className="col-5">
                                    <input className="form-control" type="number" min="0" step="any" autoComplete="off" onChange={(e) => this.setState({ cost: e.target.value })} />
                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    Discount: <strong>{parseFloat(discount).toFixed(0) + "%"}</strong>
                                </div>
                                <div className="col-5">
                                    <input className="form-control" type="number" min="0" max="100" step="any" value={discount} autoComplete="off" onChange={(e) => this.setState({ discount: e.target.value })} />
                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    Quit Quiz after: <strong>{failAt || 'Infinite'} Wrong Answers</strong>
                                </div>
                                <div className="col-2 inline gap align-items-center">
                                    <div>
                                        <input type="checkbox" className="checkbox-square" autoComplete="off" checked={failAt}
                                            onChange={(e) => this.setState({ failAt: failAt ? 0 : 2 })} />
                                    </div>
                                    <input className="form-control" type="number" min="0" max="100" step="any" value={failAt || ''} autoComplete="off" onChange={(e) => this.setState({ failAt: parseInt(e.target.value) })} />
                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    <strong>{Qattempt || 'Infinite'}</strong> Quiz Attempts per purchase
                                </div>
                                <div className="col-2 inline gap align-items-center">
                                    <div>
                                        <input type="checkbox" className="checkbox-square" autoComplete="off" checked={Qattempt}
                                            onChange={() => this.setState({ Qattempt: Qattempt ? 0 : 1 })} />
                                    </div>
                                    <input className="form-control" type="number" min="1" max="100" step="any" value={Qattempt || ''} autoComplete="off" onChange={(e) => this.setState({ Qattempt: parseInt(e.target.value) })} />

                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    <strong>{QuestAttempt || 'Infinite'}</strong> Question Attempts
                                </div>
                                <div className="col-2 inline gap align-items-center">
                                    <div>
                                        <input type="checkbox" className="checkbox-square" autoComplete="off" checked={QuestAttempt}
                                            onChange={() => this.setState({ QuestAttempt: QuestAttempt ? 0 : 2 })} />
                                    </div>
                                    <input className="form-control" type="number" min="1" max="100" step="any" value={QuestAttempt || ''} autoComplete="off" onChange={(e) => this.setState({ QuestAttempt: parseInt(e.target.value) })} />
                                </div>
                            </div>
                        </center>
                        <br />
                        <center>
                            <div className='inline choice' >
                                <div className="col-5 align-left">
                                    Passing Quiz Percentage: <strong>{parseFloat(passing).toFixed(0)}%</strong>
                                </div>
                                <div className="col-5">
                                    <input className="form-control" type="number" min="0" max="100" step="any" value={passing} autoComplete="off" onChange={(e) => this.setState({ passing: e.target.value })} />
                                </div>
                            </div>
                        </center>
                    </div>
                </div>
                <center>
                    <button className="size-lrg less-round dropShadow" onClick={() => this.createQuiz()}>Submit</button>
                </center>
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    createQuiz: userActions.createQuiz
};

const connectedAddQuizPage = connect(mapState, actionCreators)(AddQuizPage);
export { connectedAddQuizPage as AddQuizPage };