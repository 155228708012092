import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class UrlConformationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const { token } = this.props.session
        this.props.urlConformation(token, this.props.id) 
    }


    quizName() {
        const { session } = this.props; 
        console.log(session)
        return session.quiz.quizName
    }

    addQuiz(e) {
        const { token } = this.props.session   
        this.props.newAttempt(token, this.props.id)
    } 

    goBack(e) {
        console.log("Going back")
        userActions.reroute("user")
    } 

    render() {
        return (
            <form onSubmit={this.handleQuiz}>
                <div className='flex-from'>
                    <div className="lrg-container">
                        <div className='flex-form'>
                                <h1>Are you sure you want to add this quiz?</h1>
                            
                                {this.quizName()}

                                <center>
                                    <button className="size-sml" type='button' onClick={() => this.addQuiz()}>Yes</button>
                                    <button className="size-sml" type='button' onClick={() => this.goBack()}>No</button>
                                </center>

                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    urlConformation: userActions.urlConformation,
    newAttempt: userActions.newAttempt,
};

const urlConformationConnection = connect(mapState, actionCreators)(UrlConformationPage);
export { urlConformationConnection as UrlConformationPage };