import React, { Component, Fragment } from 'react';
import { FileViewer } from './FileViewer';

export class QuestionPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWrong: false
        }
    }
    createChoices() {
        const { question } = this.props;

        const choices = question.choices.map((a, index) =>
            <center>
                <label className="size-lrg inline gap contain choice" id={index} htmlFor={`answer-${index}`}>
                    <input required type="checkbox" checked={question.answers.includes(`${index}`)} id={`answer-${index}`} className={`checkbox-${question.radioButton ? "square" : "round"} embed-responsive embed-responsive-1by`}
                        onChange={(e) => this.setChoices(e.target.id.split("-")[1])} />
                    {a}
                </label>
            </center>
        )
        if (question.scrambleChoices) return this.shuffle(choices);
        return choices;
    }

    render() {
        const { question } = this.props;
        if (question) return (<Fragment>
            <form className="lrg-container contain" style={{ pointerEvents: "none" }}>
                {!this.state.showWrong ? <Fragment>
                    <center style={{ pointerEvents: "auto" }}>
                        <h1 className="questionNumber"></h1>
                        <label htmlFor="question" name="question" id="question" className="col-10 question">{question.question.split('\n').map((a, index) => (<Fragment>{index > 0 && <br />}{a}</Fragment>))}</label>
                        <FileViewer file={question.file} />
                    </center>

                    <div className="flex-form gap">
                        {this.createChoices()}
                    </div>

                    <center>
                        <button type='button' className="size-sml" style={{ pointerEvents: "auto" }} onClick={() => this.setState({ showWrong: true })}>Show Hint</button>
                    </center>
                </Fragment> : <Fragment>
                    <center>
                        Hint:
                        <div className="size-lrg">
                            <label className="question">
                                {question.wrong.split('\n').map((a, index) => (<Fragment>{index > 0 && <br />}{a}</Fragment>))}
                            </label>
                            {question.wrongFile && <Fragment>
                                <FileViewer file={question.wrongFile} />
                            </Fragment>}
                        </div>
                    </center>
                    Attempts Remaining: #
                    <center>
                        <button type="button" className="size-sml" style={{ pointerEvents: "auto" }} onClick={() => this.setState({ showWrong: false })}>Return To Question</button>
                    </center>
                </Fragment>
                }
            </form>
        </Fragment>);
    }
}