import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fN: 'loading info',
            lN: 'loading info',
            dep: 'loading info',
            em: 'loading info',
            pas: ['', ''],
            editing: false
        }
        this.updateInfo = this.updateInfo.bind(this)
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('k');

        const { token } = this.props.session;
        if (myParam) this.props.confirmAccountChanges(myParam)
        else this.props.getAccountInfo(token)
    }

    updateInfo(e) {
        e.preventDefault()
        const { token } = this.props.session;
        const { firstName, lastName, department, email } = this.props.auth;
        const { fN, lN, dep, em, pas } = this.state;
        var changes = {};
        if (fN && fN != firstName) changes.firstName = fN;
        if (lN && lN != lastName) changes.lastName = lN;
        if (dep && dep != department) changes.department = dep;
        if (em && em != email) changes.email = em;
        if (pas[0] && pas[0] === pas[1]) changes.password = pas[0];
        this.props.updateAccountInfo(token, changes);
    }

    render() {
        const { firstName, lastName, department, email } = this.props.auth;
        const { fN, lN, dep, em, pas, editing } = this.state;
        return (<Fragment>
            <form className='med-container' onSubmit={(e) => this.updateInfo(e)}>
                {editing ? <h1 className="profileTitle">Edit Info:</h1> :
                    <h1 className="profileTitle">Account:</h1>}
                <center>
                    <div className='size-lrg flex-form gap'>
                        <div className='inline spc-between gap wrap'>First Name: {editing ?
                            <input className="text" value={fN} onChange={(e) => this.setState({ fN: e.target.value })} placeholder={firstName} /> :
                            firstName || fN}
                        </div>
                        <div className='inline spc-between gap wrap'>Last Name: {editing ?
                            <input className="text" value={lN} onChange={(e) => this.setState({ lN: e.target.value })} placeholder={lastName} /> :
                            lastName || lN}
                        </div>
                        <div className='inline spc-between gap wrap'>Department: {editing ?
                            <input className="text" value={dep} onChange={(e) => this.setState({ dep: e.target.value })} placeholder={department} /> :
                            department || dep}
                        </div>
                        <div className='inline spc-between gap wrap'>Email: {editing ?
                            <input className="text" value={em} type="email" onChange={(e) => this.setState({ em: e.target.value })} placeholder={email} /> :
                            email || em}
                        </div>
                        <div className='inline spc-between gap wrap'>Password: {editing ?
                            <input className="text" value={pas[0]} type="password" onChange={(e) => this.setState({ pas: [e.target.value, pas[1]] })} placeholder="Password" /> :
                            '********'}
                        </div>
                        {pas[1] &&
                            <div className='inline spc-between gap wrap'>Confirm Password:
                                <input className="text" value={pas[1]} type="password" onChange={(e) => this.setState({ pas: [pas[0], e.target.value] })} placeholder="Confirm Password" />
                            </div>
                        }
                    </div>
                </center>
                <div className='flex-form gap spc-even'>
                    <center className='inline spc-even'>
                        {editing ? <Fragment>
                            <button class="size-sml less-round" type='submit'>Submit</button>
                            <button class="size-sml less-round" type='button' onClick={() => this.setState({ editing: false })}>Cancel</button>
                        </Fragment>
                            : <button class="size-sml less-round" type='button' onClick={() => this.setState({
                                editing: true, fN: '', lN: '', dep: '', em: '', pas: ['', '']
                            })}>Edit Profile</button>
                        }
                    </center>
                    <div>
                        <a href='/user' type='button' class="size-sml less-round">View Quizzes</a>
                    </div>
                </div>
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session, auth } = state;
    return { session, auth };
}

const actionCreators = {
    getAccountInfo: userActions.getAccountInfo,
    updateAccountInfo: userActions.updateAccountInfo,
    confirmAccountChanges: userActions.confirmAccountChanges,
};

const connectedProfilePage = connect(mapState, actionCreators)(ProfilePage);
export { connectedProfilePage as ProfilePage };