import React, { Component, Fragment } from 'react';

export class FileViewer extends Component {
    constructor(props) {
        super(props);
        this.isImg = new RegExp(/\.((jpg)|(jpeg)|(gif)|(png)|(apng)|(svg)|(bmp)|(ico)|(webp))$/, "i")
        this.isVid = new RegExp(/\.((mp4)|(ogg)|(webm))$/, "i")
        this.isYoutube = new RegExp(/^((https:\/\/www.youtube.com\/)|(https:\/\/youtu.be\/))/, 'i')
        this.isPanopto = new RegExp(/^((https:\/\/boisestate.hosted.panopto.com\/))/, 'i')
        this.isPdf = new RegExp(/.pdf$/, "i")
    }

    render() {
        const isImg = this.isImg.test(this.props?.file || '')
        const isVid = this.isVid.test(this.props?.file || '')
        const isYoutube = this.isYoutube.test(this.props?.file || '')
        const isPanopto = this.isPanopto.test(this.props?.file || '')
        const isPdf = this.isPdf.test(this.props?.file || '')
        return (<Fragment>
            {this.props?.file && <Fragment>
                {isImg && <img className='mt-2' style={{ maxWidth: '100%' }} src={this.props.file}></img>}
                {isVid && <video className='mt-2' style={{ width: '100%', height: 'auto' }} controls>
                    <source src={this.props.file} type={`video/${this.props.file.split('.').splice(-1)}`} />
                </video>}
                {isYoutube && <iframe className='mt-2' style={{ width: '100%', aspectRatio: '16/9' }} src={`https://www.youtube.com/embed/${this.props.file.split(this.isYoutube).pop()}`} />}
                {isPanopto && <iframe className='mt-2' src={this.props?.file} style={{ width: '75%', aspectRatio: '16/9', margin: '0 0 2rem 0' }} allowFullScreen allow="autoplay" />}
                {isPdf && <embed src={this.props.file} style={{ width: "800px", aspectRatio: '11/11' }} />}
                {!isPanopto && !isImg && !isVid && !isYoutube && !isPdf && this.props.showSupported && <Fragment>
                    <div>This URL: <span style={{ color: 'grey' }}>{this.props.file}</span> is not supported by the current options those being:</div>
                    <div>The url must first be an absolute url</div>
                    <div>For videos we support: mp4, ogg, and webm </div>
                    <div>For images we support: jpg, jpeg, gif, png, apng, svg, bmp, ico, and webp</div>
                    <div>Extras we also support: pdf, and Youtube videos</div>
                </Fragment>}
            </Fragment>}
        </Fragment>);
    }
}