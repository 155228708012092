import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { userActions } from '../../actions';

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }
        this.login = this.login.bind(this);
    }

    login(e) {
        e.preventDefault()
        const { password, email } = this.state;
        this.props.signin(email, password)
    }

    render() {
        if (this.props.session.clearance == "admin") {
            userActions.reroute("admin/view")
        }

        return (<Fragment>
            <form className="med-container" onSubmit={this.login}>
                <p>Please type your first and last name, on the line below to sign back in.</p>
                <center>
                    <div className="size-lrg blocky">
                        <label htmlFor="inputEmail">Email</label>
                        <input type="email" className="form-control" onChange={(e) => this.setState({ email: e.target.value })} required />
                        <label htmlFor="inputEmail">Password</label>
                        <input type="password" className="form-control" onChange={(e) => this.setState({ password: e.target.value })} required />
                    </div>
                </center>
                <center>
                    <button className="size-sml">Submit</button>
                </center>
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    signin: userActions.signin
};

const connectedAdminPage = connect(mapState, actionCreators)(AdminPage);
export { connectedAdminPage as AdminPage };