import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FileViewer, MediaUpload } from '../../Components';
import { alertActions, userActions } from '../../actions';

class AddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "",
            file: "",
            choices: [],
            answers: new Set(),
            wrong: "",
            wrongFile: "",
            scrambleChoices: false,
            panoptoURL: "",
            tags: [],
            tag: ""
        }
        this.generateQuestion = this.generateQuestion.bind(this);
        this.saveQuestion = this.saveQuestion.bind(this);
    }

    componentDidMount() {
        if (this.props.session.clearance != "admin") {
            userActions.reroute("admin")
        }
    }

    generateQuestion() {
        let { choices, answers } = this.state;

        var ql = choices.map((q, index) => {
            return <center>
                <label className="inline gap choice mb-2" htmlFor={index}> 
                    <input type="checkbox" id={index} className={`checkbox-${answers.size > 1 ? "square" : "round"}`} autoComplete="off" checked={answers.has(`${index}`)} onChange={(e) => {
                        if (answers.has(e.target.id)) answers.delete(e.target.id);
                        else answers.add(e.target.id);
                        this.setState({ answers: answers });
                    }} />
                    <input className="form-control" id={index} value={q} required autoFocus autoComplete="off" onChange={(e) => {
                        if (e.target.value === "") {
                            answers = new Set([...answers].map(a => {
                                if (e.target.id < a) return `${a - 1}`
                                else if (e.target.id == a) return -1
                                return a
                            }).filter(a => a !== -1));
                            choices.splice(e.target.id, 1)
                        } else {
                            choices[e.target.id] = e.target.value
                            this.setState({ choices: choices, answers: answers })
                        }
                    }} />
                </label>
            </center>
        })

        ql.push(
        <center>
            <h1 className="fs-5 align-left ms-4">New Answer</h1>
            <label className="inline gap choice">
                <div className={`checkbox-${answers.size > 1 ? "square" : "round"}`} />
                <input className="form-control" id={choices.length} value="" autoComplete="off" onChange={(e) => {
                    choices.push(e.target.value)
                    this.setState({ choices: choices })
                }} placeholder="Type here to add a new answer" />
            </label>
        </center>
        )

        return ql
    }

    saveQuestion(e) {
        this.props.clearAlerts()

        e.preventDefault()
        const { panoptoURL, question, file, choices, answers, wrongFile, wrong, scrambleChoices, tags } = this.state;
        const { token } = this.props.session;
        if (answers.size > 0) {
            this.props.createQuestion(token, {
                file,
                question,
                choices,
                wrongFile,
                answers: [...answers],
                wrong,
                scrambleChoices,
                panoptoURL,
                tags,
            })
        } else {
            setTimeout(() => this.props.alertError("Correct answer needed to save question. Click one of the radio buttons under Choices to select a correct answer."), 0)
        }
    }

    addTag() {
        const {tag, tags} = this.state
        if(tag != "") {
            tags.push(tag);
            this.setState({ tag: "", tags: tags })
        }
    }

    deleteTag(e) {
        const { tags } = this.state
        tags.splice(e, 1)
        this.setState({ tags: tags });
    }

    displayTags() {
        let { tags } = this.state;
        return tags.map((tag, index) => (
            <Fragment>
                <div className='tags-container tags-list'>
                    <div key={index}>{tag}</div>
                    <button type="button" className="createButtons d-flex" name="action"
                        onClick={(e) => this.deleteTag(e.target.value)} value={index}>
                            Delete
                    </button>
                </div>
            </Fragment>
        ))
    }

    render() {
        const { session } = this.props;
        const { panoptoURL, file, wrongFile, question, wrong, scrambleChoices, tag } = this.state;
        return (<Fragment>
            <form className="creation" onSubmit={(e) => this.saveQuestion(e)}>
                    <label className="p-0 mb-4 row fs-1">
                        <p>Question Number : {session?.questions?.length + 1 || 1} </p>
                    </label>
                    <div className="lrg-container contain border border-2 border-black">
                    <div className="form-group row">
                        <div>
                            <p className="fs-1">Question Name</p>
                            <textarea className="form-control" id="inputQuestion" name="inputQuestion" rows={question.split("\n").length} required autoComplete="off" onChange={(e) => this.setState({ question: e.target.value })} />
                        </div>
                    </div>
                    <br />
                    <div style={{ position: 'relative', textAlign: "left", marginLeft: '5%' }}>
                        <MediaUpload updateURL={(e) => this.setState({ file: `${userActions.API.split('/api')[0]}${e}` })} />
                        <div style={{ display: 'inline-block', width: '45%', top: '0px', position: 'absolute' }}>
                            <center>URL</center>
                            <input className="form-control" id="inputQuestion" name="inputQuestion" autoComplete="off"
                                placeholder='https://somedomain.com/filename.extention' value={file} onChange={(e) => this.setState({ file: e.target.value })} />
                        </div>
                    </div>
                    <br />
                    <FileViewer file={file} showSupported />
                </div>

                <div className="lrg-container contain border border-2 border-black">
                    <label className="p-0 m-0">
                        <p className="fs-1">Choices</p>
                    </label>
                    <div className="form-group">
                        <h5>Select the checkboxes for the correct answers on the left.</h5>
                        <div>
                            {this.generateQuestion()} 
                        </div>
                    </div>
                </div>

                <div className="lrg-container contain border border-2 border-black">
                    <p className="fs-1">Wrong Answer Text</p>
                    <center>
                        <div className="col-12">
                            <textarea className="form-control" id="inputWrong" name="inputWrong" rows={wrong.split("\n").length} required autoComplete="off" onChange={(e) => { this.setState({ wrong: e.target.value }) }} />
                        </div>
                        <br />
                        <div style={{ position: 'relative', textAlign: "left", marginLeft: '5%' }}>
                            <MediaUpload updateURL={(e) => this.setState({ wrongFile: `${userActions.API.split('/api')[0]}${e}` })} />
                            <div style={{ display: 'inline-block', width: '45%', top: '0px', position: 'absolute' }}>
                                <center>URL</center>
                                <input className="form-control" id="inputQuestion" name="inputQuestion" autoComplete="off"
                                    placeholder='https://somedomain.com/filename.extention' value={wrongFile} onChange={(e) => this.setState({ wrongFile: e.target.value })} />
                            </div>
                        </div>
                    </center>
                </div>

                <div className="lrg-container contain border border-2 border-black">
                        <p className="fs-1">Add Tags</p>
                        <div class="tags-container tag-input-field">
                            <input className="form-control me-auto questionText" autoComplete="off" placeholder='Type your tag here' value={tag} onChange={(e) => this.setState({ tag: e.target.value })} />
                            <button type="button" className="quiz add-tag-btn" 
                                onClick={() => this.addTag()} >
                                Add
                            </button>
                        </div>
                        {this.displayTags()}
                    </div>

                <div className="lrg-container contain border border-2 border-black">
                    <p>Other Options</p>
                    <center>
                        <label className="inline choice align-left" htmlFor="shuffle">
                            <div className="col-2">
                                Shuffle Choices
                            </div>
                            <div className="col-6">
                                <input id="shuffle" type="checkbox" className="checkbox-round" autoComplete="off" checked={scrambleChoices}
                                    onChange={(e) => this.setState({ scrambleChoices: e.target.checked })} />
                            </div>
                        </label>
                    </center>
                </div>

                <center>
                    <button className="size-lrg less-round dropShadow">Submit</button>
                </center>
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    createQuestion: userActions.createQuestion,
    alertError: alertActions.error,
    clearAlerts: alertActions.clear,
};

const connectedAddPage = connect(mapState, actionCreators)(AddPage);
export { connectedAddPage as AddPage };