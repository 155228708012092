import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stripe from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { alertActions, userActions } from "../../actions";

const PaymentProcessing = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentkey = useSelector((state) => state.session.payment);
  const stripePromise = loadStripe(
    "pk_test_51Mk9CgDTSTfxFf4xrPuOlmhuwhF9KD5BVChX1QqCFfOugwTSxunfDmxJvQ4BF3ETxcFVaxIVS1rSOvqJv2UYSm5600PYyZXMlr"
  );

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      console.log(props);

      props.finishPayment(props.token, props.amount, props.id);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <button disabled={!stripe}>Submit</button>
    </form>
  );
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
  archiveAttempt: userActions.archiveAttempt,
  startANewAttempt: userActions.startANewAttempt,
  getAttempts: userActions.getAttempts,
  getQuizzes: userActions.getQuizzes,
  newAttempt: userActions.newAttempt,
  initPayment: userActions.initPayment,
  resetPayment: userActions.resetPayment,
  finishPayment: userActions.finishPayment,
};

const connectedPaymentPage = connect(
  mapState,
  actionCreators
)(PaymentProcessing);
export { connectedPaymentPage as PaymentProcessing };
