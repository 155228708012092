import { combineReducers } from 'redux';
import { session } from './session.reducer';
import { alert } from './alert.reducer';
import { auth } from './auth.reducer'

const rootReducer = combineReducers({
    session,
    auth,
    alert
});

export default rootReducer;