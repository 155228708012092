import { userConstants } from './constants';

const INITIALSTATE = {
    questions: JSON.parse(localStorage.getItem('AdQuestion')) || [],
    quizzes: JSON.parse(localStorage.getItem('AdQuizzes')) || [],
    payment: JSON.parse(localStorage.getItem('payment')) || [],
    quiz: JSON.parse(localStorage.getItem('getQuizByToken')) || [],
    clearance: "unknown",
    ...checkToken()
};

export function session(state = INITIALSTATE, action) {
    var q
    const nextState = { ...state };
    if (state.clearance != "admin") {
        localStorage.removeItem("AdQuestion")
        localStorage.removeItem("AdQuizzes")
        localStorage.removeItem("getQuizByToken")
    }
    switch (action.type) {
        case userConstants.urlConformationSUCCESS:
            localStorage.setItem('getQuizByToken', JSON.stringify(action.quiz));
            return { ...state, quiz: action.quiz }
        case userConstants.archiveAttemptSUCCESS:
            return { ...state, attempts: state.attempts.map(a => ((a.attemptId == action.attemptId) ? { ...a, quizAttempts: 0 } : a)) }
        case userConstants.submitAttemptSUCCESS:
            return { ...state, attempt: { ...state.attempt, score: action.score, quizName: action.quizName, failedFromWrongAnswers: action.failedFromWrongAnswers, passed: action.passed } }
        case userConstants.resetLast:
            delete nextState.wrong;
            delete nextState.wrongFile;
            delete nextState.retry;
            nextState.attempt.answers.pop()
            return nextState;
        case userConstants.removeWrong:
            delete nextState.wrong;
            delete nextState.wrongFile;
            delete nextState.retry;
            return nextState;
        case userConstants.wrongQuestionAnswer:
            return { ...state, wrong: action.wrong, wrongFile: action.wrongFile || null, retry: action.retry, lastId: action.lastId }
        case userConstants.saveAttemptSUCCESS:
            return { ...state, attempt: { ...state.attempt, answers: action.answers } }
        case userConstants.resumeAttemptSUCCESS:
            return { ...state, attempt: action.attempt }
        case userConstants.newAttemptSUCCESS:
            return { ...state, attempts: [...(state.attempts || []), action.attempt] }
        case userConstants.getAttemptsSUCCESS:
            return { ...state, attempts: action.attempts }
        case userConstants.LOGOUT:
            localStorage.clear()
            return { clearance: "unknown" };
        case userConstants.getQuizzesSUCCESS:
            localStorage.setItem('AdQuizzes', JSON.stringify(action.quizzes));
            return { ...state, quizzes: action.quizzes }
        case userConstants.getQuestionsSUCCESS:
            localStorage.setItem('AdQuestion', JSON.stringify(action.questions));
            return { ...state, questions: action.questions }
        case userConstants.deleteQuestionSUCCESS:
            q = state.questions.filter(a => a._id != action.questionId)
            localStorage.setItem('AdQuestion', JSON.stringify(q));
            return { ...state, questions: q }
        case userConstants.deleteQuizSUCCESS:
            q = state.quizzes.filter(a => a._id != action.quizId)
            localStorage.setItem('AdQuizzes', JSON.stringify(q));
            return { ...state, quizzes: q }
        case userConstants.verifyKeySUCCESS:
            localStorage.setItem('token', action.token);
            return { ...state, token: action.token, pass: true }
        case userConstants.CREATE:
            localStorage.setItem('token', action.token);
            return { ...state, token: action.token };
        case userConstants.createAccountPasswordSUCCESS:
        case userConstants.LOGIN:
            localStorage.setItem('clearance', action.clearance || 'unknown')
            localStorage.setItem('token', action.token);
            return { token: action.token, clearance: action.clearance || 'unknown' };

            case userConstants.getPaymentSUCCESS:
                localStorage.setItem('pay', JSON.stringify(action.payment));
                return { ...state, payment: action.payment,callNextFunc:true }

            case userConstants.showPayment:
                   
                    return { ...state, callNextFunc:false }
        default:
            return state;
    }
}

function checkToken() {
    // get saved object
    var token = localStorage.getItem('token') || "";
    var clearance = localStorage.getItem('clearance') || "unknown";
    if (clearance != "admin") {
        localStorage.removeItem("AdQuestion")
        localStorage.removeItem("AdQuizzes")
    }

    if (token) {
        try {
            // decode token
            var decoded = JSON.parse(atob(token.split('.')[1]));
            // check the token creation date
            if (new Date(decoded.echo).setHours(new Date(decoded.echo).getHours() + 4).valueOf() > new Date().valueOf())
                return {
                    token: token,
                    clearance: clearance
                };
            else {
                // remove saved objects if expired
                localStorage.clear();
                // return a warning
                return {
                    token: "",
                    clearance: 'unknown',
                    warning: 'Your account signin token has expired please sign back in'
                }
            }
        } catch (err) { }
    }

    return {
        token: "",
        clearance: "unknown"
    }
}