import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class KeyConformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const { token } = this.props.session
    }

    addAttempts(e) {
        const { token } = this.props.session 
        const { urlToken } = this.props  
        this.props.addKeys(token, urlToken);
    } 

    goBack(e) {
        userActions.reroute("user")
    } 

    render() {
        return (
            <form onSubmit={this.handleQuiz}>
                <div className='flex-from'>
                    <div className="lrg-container">
                        <div className='flex-form'>
                                <h1>Are you sure you want to allow these attempts?</h1>
                                <center>
                                    <button className="size-sml" type='button' onClick={() => this.addAttempts()}>Approve</button>
                                    <button className="size-sml" type='button' onClick={() => this.goBack()}>Cancel</button>
                                </center>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    addKeys: userActions.addKeys,
};

const KeyConformationConnection = connect(mapState, actionCreators)(KeyConformation);
export { KeyConformationConnection as KeyConformation };