import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { alertActions, userActions } from "../../actions";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stripe from "@stripe/stripe-js";
import { PaymentProcessing } from "./PaymentProccessing";
import { userConstants } from "../../redux/constants";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutText: <></>,
      stripePromise: null,
      elements: null,
      showPayment: false,
      amount: 0,
      id: null,
      quizAmounts: {},
      boiseStateUser: false,
    };

    //this.newAttempt = this.newAttempt.bind(this),
    this.checkout = this.checkout.bind(this);
  }

  componentDidMount() {
    if (this.props.session.clearance === "unknown")
      userActions.reroute("login");
    const stripe = loadStripe(
      "pk_test_51Mk9CgDTSTfxFf4xrPuOlmhuwhF9KD5BVChX1QqCFfOugwTSxunfDmxJvQ4BF3ETxcFVaxIVS1rSOvqJv2UYSm5600PYyZXMlr"
    ); // Your Publishable Key

    const { token } = this.props.session;
    this.props.getAttempts(token);
    this.props.getQuizzes(token);
    this.props.getAccountInfo(token);
    this.setState({ stripePromise: stripe });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.session.callNextFunc) {
      console.log(nextProps);
      this.finalizeCheckout();
      this.props.resetPayment();
    }

    return true;
  }

  newAttempt(e) {
    const { token } = this.props.session;
    this.props.newAttempt(token, e);
  }

  resumeAttempt(e) {
    userActions.reroute(`user/quiz?A=${e}`);
  }

  startANewAttempt(e) {
    const { token } = this.props.session;
    this.props.startANewAttempt(token, e);
  }
  archiveAttempt(e) {
    const { token } = this.props.session;
    this.props.archiveAttempt(token, e);
  }
  formAttempts(completed) {
    const { session } = this.props;
    var att = session.attempts || [];
    if (completed) {
      att = att.filter((a) => a.submitted && a.quizAttempts == 0);
      let best = {};
      att.forEach((b) => {
        if (!best[b.quizId]) {
          best[b.quizId] = b;
        } else if (best[b.quizId].score < b.score) {
          best[b.quizId] = b;
        }
      });
      att = Object.getOwnPropertyNames(best).map((b) => best[b]);
    } else att = att.filter((a) => !a.submitted || a.quizAttempts > 0);
    return (
      att?.map((a) => {
        return (
          <div
            className="inline gap"
            style={a.submitted ? { transform: "translateX(3px)" } : {}}
          >
            <div className="size-lrg inline wrap spc-between whiteText ">
              {!!a.quizAttempts && (
                <div className="attempts-left ">
                  Attempts Left: {a.quizAttempts}
                </div>
              )}

              <div className="repoHeader">{a.quizName}</div>

              <div className="status">
                {a.submitted ? (
                  <Fragment>
                    Best Score:{" "}
                    {
                      <strong>
                        {parseFloat(a.score || 0).toFixed(0) + "%"}
                      </strong>
                    }
                  </Fragment>
                ) : (
                  <Fragment>Incomplete</Fragment>
                )}
              </div>
            </div>
            {a.submitted && a.quizAttempts == 0 ? (
              <div />
            ) : (
              <Fragment>
                <button
                  className="quiz"
                  onClick={(e) =>
                    a.submitted
                      ? this.startANewAttempt(e.target.value)
                      : this.resumeAttempt(e.target.value)
                  }
                  value={a.attemptId}
                >
                  {a.submitted
                    ? "Redo "
                    : a.started
                    ? "Resume"
                    : "Start Quiz"}
                </button>
              </Fragment>
            )}
          </div>
        );
      }) || []
    );
  }

  async checkout(cost, amount, id) {
    const { session } = this.props;
    console.log(localStorage.getItem("pay"));
    console.log(amount);
    console.log(cost * 100 * parseInt(amount), id);
    if (amount == 0) return;
    var test = await this.props.initPayment(
      cost * 100 * parseInt(amount),
      amount,
      id
    );

    console.log(test);

    // this.finalizeCheckout();
  }

  finalizeCheckout() {
    this.setState({ showPayment: true });
    return;

    const { session } = this.props;
    const { stripePromise } = this.state;

    console.log(session);
    const stripe = loadStripe(
      "pk_test_51Mk9CgDTSTfxFf4xrPuOlmhuwhF9KD5BVChX1QqCFfOugwTSxunfDmxJvQ4BF3ETxcFVaxIVS1rSOvqJv2UYSm5600PYyZXMlr"
    ); // Your Publishable Key

    const appearance = {
      theme: "stripe",
    };
    var elements = stripe.elements(session.payment);
    this.setState({ elements: elements });
    const options = {
      // passing the client secret obtained from the server
      clientSecret: session.payment,
    };
    this.setState({
      checkoutText: (
        <Elements stripe={stripePromise} options={options}>
          <PaymentElement />
          <button type="button" onClick={this.sendPayment}>
            Submit
          </button>
        </Elements>
      ),
    });
  }

  sendPayment() {
    const paymentElementOptions = {
      layout: "tabs",
    };
    const { elements } = this.state;
    console.log(elements);
    const paymentElement = elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  }

  SendKey(quiz, amount) {
    this.props.clearAlerts()
    if(amount > 0 && quiz.id != null) {
      const { token } = this.props.session;
      this.props.generateKey(token, amount, quiz.id)  
    } else {
      this.props.alertError("Select an amount of quizzes to purchase.")
    }
  }   

  testQuiz(e) {
    // if (this.props.session.clearance === "admin")
    const { token } = this.props.session;
    const testToken = {type: "testAttempt", quizId: e}
    this.props.newAttempt(token, testToken)  
  }

  formQuizzes() {
    const { session } = this.props;
    const { quizAmounts, boiseStateUser } = this.state;
    const { email } = this.props.auth;

    if((email.endsWith("@u.boisestate.edu") || email.endsWith("@boisestate.edu")) && !boiseStateUser && this.props.session.clearance != "admin") {
      this.setState({ boiseStateUser: true });
    }

    return (
      session.quizzes?.map((q, index) => {
        const quizId = q.id;
        const amount = quizAmounts[quizId] || 0;

        return (
          <div className="inline gap">
            <div className="size-lrg" id={index}>
              <div className="inline spc-between whiteText">
                <div className="repoHeader">{q.name.length > 40 ? q.name.substring(0, 40) + "..." : q.name}</div>
                

              </div>
            </div>
            
            {boiseStateUser ? <button className="quiz genAttempt" onClick={() => this.SendKey(q, 1)}>Generate Attempt</button> : 
            <Fragment>
            <button
            className="quiz"
            onClick={() =>
              q.published
                ? this.SendKey(q, amount)
                : this.testQuiz(quizId)
            }
            value={quizId}
          >
            {q.published ? "Purchase" : "Test"}
          </button>
          <input
            className="inputSize"
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => {
              const newAmount = parseInt(e.target.value, 10) || 0;
              this.setState({
                quizAmounts: {
                  ...quizAmounts,
                  [quizId]: newAmount
                }
              });
              }}
            /> 
            </Fragment>}
          </div>
        );
      }) || []
    );
  }

  render() {
    if (this.props.session.clearance === "unknown") userActions.reroute("");
    const { attempts, payment, token } = this.props.session;
    const { checkoutText, stripePromise, showPayment } = this.state;

    const options = {
      // passing the client secret obtained from the server
      clientSecret: payment,
    };
    return (
      <Fragment>
        <div className="lrg-container flex-form contain">
          <h1 className="profileTitle">Quizzes</h1>
          {attempts &&
            attempts.filter((a) => !a.submitted || a.quizAttempts > 0).length >
              0 && (
              <div className="flex-form gap">
                <h1 className="align-left">Attempts</h1>
                {this.formAttempts(false)}
              </div>
            )}
          <div className="flex-form gap">
            <h1 className="align-left blackText fs-3">Available Quizzes</h1>
            {this.formQuizzes()}
          </div>

          {/* move to other page
                attempts && attempts.filter(a => a.submitted && a.quizAttempts == 0).length > 0 && <div>
                    <h1 className='align-left'>Previous Attempts</h1>
                    {this.formAttempts(true)}
                </div>
                */}
        </div>
        {showPayment && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentProcessing
              amount={this.state.amount}
              id={this.state.id}
              token={token}
            />
          </Elements>
        )}


      </Fragment>
    );
  }
}

function mapState(state) {
  const { session, auth } = state;
  return { session, auth };
}

const actionCreators = {
  archiveAttempt: userActions.archiveAttempt,
  startANewAttempt: userActions.startANewAttempt,
  getAttempts: userActions.getAttempts,
  getQuizzes: userActions.getQuizzes,
  newAttempt: userActions.newAttempt,
  initPayment: userActions.initPayment,
  resetPayment: userActions.resetPayment,
  generateKey: userActions.generateKey,
  getAccountInfo: userActions.getAccountInfo,

  alertError: alertActions.error,
  clearAlerts: alertActions.clear,
};

const connectedUserPage = connect(mapState, actionCreators)(UserPage);
export { connectedUserPage as UserPage };
