export const userConstants = {
    REQUEST: "sending a request to the server",
    LOGOUT: 'User Has Logged Out',
    CREATE: 'Attempting to create an acount',
    LOGIN: 'User has been signed in',
    publishREQUEST: "publish REQUEST",
    publishSUCCESS: "publish SUCCESS",
    publishFAILURE: "publish FAILURE",
    getQuestionsREQUEST: "getQuestions REQUEST",
    getQuestionsSUCCESS: "getQuestions SUCCESS",
    getQuestionsFAILURE: "getQuestions FAILURE",
    createUserSUCCESS: 'createUser SUCCESS',
    createUserREQUEST: 'createUser REQUEST',
    createUserFAILURE: 'createUser FAILURE',
    getAttemptsSUCCESS: 'getAttempts SUCCESS',
    getAttemptsREQUEST: 'getAttempts REQUEST',
    getAttemptsFAILURE: 'getAttempts FAILURE',
    getQuizSUCCESS: 'getQuiz SUCCESS',
    getQuizREQUEST: 'getQuiz REQUEST',
    getQuizFAILURE: 'getQuiz FAILURE',
    resumeAttemptSUCCESS: 'resumeAttempt SUCCESS',
    resumeAttemptREQUEST: 'resumeAttempt REQUEST',
    resumeAttemptFAILURE: 'resumeAttempt FAILURE',
    saveAttemptSUCCESS: 'saveAttempt SUCCESS',
    saveAttemptREQUEST: 'saveAttempt REQUEST',
    saveAttemptFAILURE: 'saveAttempt FAILURE',
    submitAttemptSUCCESS: 'submitAttempt SUCCESS',
    submitAttemptREQUEST: 'submitAttempt REQUEST',
    submitAttemptFAILURE: 'submitAttempt FAILURE',
    getQuizzesSUCCESS: 'getQuizzes SUCCESS',
    getQuizzesREQUEST: 'getQuizzes REQUEST',
    getQuizzesFAILURE: 'getQuizzes FAILURE',
    createQuizSUCCESS: 'createQuiz SUCCESS',
    createQuizREQUEST: 'createQuiz REQUEST',
    createQuizFAILURE: 'createQuiz FAILURE',
    editQuizSUCCESS: 'editQuiz SUCCESS',
    editQuizREQUEST: 'editQuiz REQUEST',
    editQuizFAILURE: 'editQuiz FAILURE',
    urlConformationSUCCESS: 'urlConformation SUCCESS',
    urlConformationREQUEST: 'urlConformation REQUEST',
    createQuestionSUCCESS: 'createQuestion SUCCESS',
    createQuestionREQUEST: 'createQuestion REQUEST',
    createQuestionFAILURE: 'createQuestion FAILURE',
    editQuestionSUCCESS: 'editQuestion SUCCESS',
    editQuestionREQUEST: 'editQuestion REQUEST',
    editQuestionFAILURE: 'editQuestion FAILURE',
    deleteQuestionSUCCESS: 'deleteQuestion SUCCESS',
    deleteQuestionREQUEST: 'deleteQuestion REQUEST',
    deleteQuestionFAILURE: 'deleteQuestion FAILURE',
    newAttemptREQUEST: "newAttempt REQUEST",
    newAttemptSUCCESS: "newAttempt SUCCESS",
    newAttemptFAILURE: "newAttempt FAILURE",
    wrongQuestionAnswer: "wrong Question Answer",
    removeWrong: "remove Wrong",
    resetLast: "reset Last",
    getUploadedREQUEST: "getUploadedREQUEST",
    getUploadedSUCCESS: "getUploadedSUCCESS",
    deleteQuizREQUEST: "delete Quiz REQUEST",
    deleteQuizSUCCESS: "delete Quiz SUCCESS",
    deleteQuizFAILURE: "delete Quiz FAILURE",
    verifyKeyREQUEST: 'verifyKey REQUEST',
    verifyKeySUCCESS: 'verifyKey SUCCESS',
    createAccountPasswordREQUEST: 'createAccountPassword REQUEST',
    createAccountPasswordSUCCESS: 'createAccountPassword SUCCESS',
    createAccountPasswordFAILURE: 'createAccountPassword FAILURE',
    checkEmailREQUEST: 'checkEmail REQUEST',
    resetPasswordREQUEST: 'resetPassword REQUEST',
    startANewAttemptREQUEST: 'startANewAttempt REQUEST',
    startANewAttemptSUCCESS: 'startANewAttempt SUCCESS',
    startANewAttemptFAILURE: 'startANewAttempt FAILURE',
    archiveAttemptSUCCESS: 'archiveAttempt SUCCESS',
    archiveAttemptREQUEST: 'archiveAttempt REQUEST',
    getAccountInfoREQUEST: "getAccountInfo REQUEST",
    getAccountInfoSUCCESS: "getAccountInfo SUCCESS",
    updateAccountInfoREQUEST: "updateAccountInfo REQUEST",
    confirmAccountChangesREQUEST: "confirmAccountChanges REQUEST",
    getPaymentSUCCESS: "getPayment SUCCESS",
    showPayment:"showPayment REQUEST",
    displayErrorRequest:"displayErrorRequest REQUEST"
}

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};