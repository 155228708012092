import { userConstants } from "./constants";

const INITIALSTATE = {
    firstName: '',
    lastName: '',
    department: '',
    email: ''
};

export function auth(state = INITIALSTATE, action) {
    switch (action.type) {
        case userConstants.getAccountInfoSUCCESS:
            return action.user
        default:
            return state;
    }
}