import React, { Fragment } from 'react';
import '../css/svg.css';
//import TracksSVG from '../images/tracksMuddy.svg';
import TracksSVG from '../images/track-topfade.svg';

export function TracksDecal() {
    return (<Fragment> 
        <div className="track left">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.16 237.26" height="100%"> 
                <image href={TracksSVG} height="100%"/>
            </svg>
        </div>
        <div className="track right">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.16 237.26" height="100%"> 
                <image href={TracksSVG} height="100%"/>
            </svg>
        </div>
    </Fragment>);
}