import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class RecoveryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            urlToken: new URLSearchParams(window.location.search).get('t') || '',
            password: ['', ''],
        }

        this.checkEmail = this.checkEmail.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    resetPassword(e) {
        e.preventDefault()
        const { urlToken, password } = this.state
        if (password[0] !== password[1]) return;
        this.props.resetPassword(urlToken, password[0])
    }

    checkEmail(e) {
        e.preventDefault()
        const { email } = this.state;
        this.props.checkEmail(email)
    }

    render() {
        const { urlToken, password } = this.state
        return (<Fragment>
            {urlToken ? <Fragment>
                <form className="med-container" onSubmit={this.resetPassword}>
                    <p className="blackText">Please set your new password below</p>
                    <center>
                        <div className="size-lrg blocky">
                            <label htmlFor="inputEmail" >Password</label>
                            <input className='text' type="password" onChange={(e) => this.setState({ password: [e.target.value, password[1]] })} required />
                            <label htmlFor="inputEmail" >Re-type Password</label>
                            <input className='text' type="password" onChange={(e) => this.setState({ password: [password[0], e.target.value] })} required />
                            {password[0].length > 0 && password[1].length > 0 && password[0] != password[1] &&
                                <div><span className='important'>passwords must match</span></div>
                            }
                        </div>
                    </center>

                    <center>
                        <button className='size-sml' >Submit</button>
                    </center>
                </form>
            </Fragment> : <Fragment>
                <form className="med-container" onSubmit={this.checkEmail}>
                    <p className="blackText">Please type your email on the line below</p>
                    <center>
                        <div className="size-lrg blocky">
                            <label htmlFor="inputEmail" >Email</label>
                            <input className="text" type="email" onChange={(e) => this.setState({ email: e.target.value })} required />
                        </div>
                    </center>
                    <center>
                        <button className="size-sml">Submit</button>
                    </center>
                </form>
            </Fragment>}
        </Fragment>);
    }
}

function mapState(state) {
    return {};
}

const actionCreators = {
    checkEmail: userActions.checkEmail,
    resetPassword: userActions.resetPassword
};

const connectedRecoveryPage = connect(mapState, actionCreators)(RecoveryPage);
export { connectedRecoveryPage as RecoveryPage };