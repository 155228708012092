import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';
import TrashCan from '../../images/trashcan.png';
import EditPen from '../../images/editpen.png';
import { Modal } from 'react-bootstrap';

class OverviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteQueue: "",
            showModal: false,
            quizToDelete: null,
            questionToDelete: null,
            type: null,
            questionsIds: [],
            query: "$",
        }
    }

    componentDidMount() {
        if (this.props.session.clearance != "admin") {
            userActions.reroute("admin")
        }

        const { token } = this.props.session
        this.props.getQuestions(token)
        this.props.getQuizzes(token, true)
    }

    publish(quizId) {
        const { token } = this.props.session
        this.props.publish(token, quizId)
    }

    handleCloseModal = () => {
        //This makes sure nothing is stored to potentially delete before closing out of the modal
        this.setState({quizToDelete: null, questionToDelete: null, type: null, deleteQueue: null}, function() {
            this.closeModal();
        });
    };
    closeModal() {
        this.setState({ showModal: false });
    }
    handleShowModal = (e, type) => {
        //This makes sure the quiz/question that needs to be deleted is updated in state before showing the modal
        if(type === "quiz") {
            this.setState({quizToDelete: e, type: type}, function() {
                this.showModal();
            });
        } else if (type === "question") {
            this.setState({questionToDelete: e, type: type}, function() {
                this.showModal();
            });
        }
    };
    showModal() {
        this.setState({showModal: true  });
    }

    //Modal shows up based on the "show" prop
    //Because of this, I was unable to get the modal to show up and pass info into the modal function
    //Currently the modal's information is managed through state instead of arguments
    modal() {
        const { deleteQueue } = this.state;
        let deleteButton
        if (this.state.type === "quiz") {
            deleteButton =
            <Fragment>
                <div>
                    <h2>Are you sure you want to delete this quiz?</h2>
                </div>
                <button className={"d-flex delete-quiz justify-content-center" + ((deleteQueue == `Quiz:${this.state.quizToDelete}`) ? " warning" : "")} name="delete"
                    value={this.state.quizToDelete}
                    onClick={(e) => {
                        this.deleteQuiz2(this.state.quizToDelete);
                        this.handleCloseModal();
                    }}>
                    Delete
                </button>
            </Fragment>
        } else if (this.state.type === "question") {
            //Only h2 and OnClick change from quiz condition
            deleteButton =
            <Fragment>
                <div>
                    <h2>Are you sure you want to delete this question?</h2>
                </div>
                <button className={"d-flex delete-quiz justify-content-center" + ((deleteQueue == `Quiz:${this.state.quizToDelete}`) ? " warning" : "")} name="delete"
                    value={this.state.quizToDelete}
                    onClick={(e) => {
                        this.deleteQuestion2(this.state.questionToDelete);
                        this.handleCloseModal();
                    }}>
                    Delete
                </button>
            </Fragment>
        } else {
            //In case type is not question or quiz for some reason, don't show the modal
            return 
        }
        return <Fragment>
            <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {deleteButton}
                </Modal.Body>
            </Modal>
        </Fragment>
    }

    formQuizzes() {
        const { deleteQueue } = this.state
        const { session } = this.props;
        return session.quizzes?.map((q, index) => {
            return (<div className="uline">
                <div className="inline gap align-items-center">

                    <div className="quizText d-flex me-auto left-text">
                        <p className="blackText fs-4 m-0">{q.name}</p>
                    </div>
                    <h4>Published: </h4>


                    <div className="form-check form-switch">
                        {/* Store if the quiz is published in state so the page doesn't need to be refreshed when changing the value */}
                        <input className="form-check-input" type="checkbox" role="switch" id="publishedCheck"  onChange={(e) => this.publish(q.id)} checked={q.published}/>
                        <label className="form-check-label" for="publishedCheck"></label>
                    </div>
                    <button className="shadowButtons dropShadow d-flex justify-content-center " id={index} name="action" onClick={(e) => this.editQuiz(e.target.value, q.published)} value={index}> 
                        <img src={EditPen} className="trashSize align-self-center" value={index}/>
                    </button>
                    <button className={"shadowButtons dropShadow d-flex justify-content-center" + ((deleteQueue == `Quiz:${index}`) ? " warning" : "")} name="delete"
                        onClick={(e) => this.handleShowModal(e.target.value, "quiz")} value={q.id}>
                        <img src={TrashCan} className="trashSize align-self-center" value={index}/>
                    </button>
                </div>

                {q.timesAttempted ? 
                <div className="passed-failed-total">
                    <span>Passed: {q.timesAttempted.pass}</span>
                    <span>Failed: {q.timesAttempted.fail}</span>
                    <span>Total: {q.timesAttempted.pass + q.timesAttempted.fail}</span>
                </div>
                : <></>}

            </div>)
        }) || []
    }

    editQuestion(e) {
        const {questions} = this.props.session;

        //Filtering makes it so the question number passed in is out of order
        //Finding the index in the questions array based on the question id
        let questionIndex = questions.findIndex(question => question.id === e);
        userActions.reroute(`admin/edit?Q=questions-${questionIndex}`)
    }

    editQuiz(e, published) {
        if(published) {
            this.props.displayError("Unable to edit published quiz")
        } else {
            userActions.reroute(`admin/edit?Q=quizzes-${e}`)
        }
    }

    //Audrey's version it is working the other one....no idea
    deleteQuiz2(e) {
        const { quizzes, token } = this.props.session
        const _q = quizzes[e]
        this.props.deleteQuiz(token, e)
    }

    deleteQuiz(e) {
        const { deleteQueue, resetQueue } = this.state
        if (deleteQueue != `Quiz:${e}`) {
            clearTimeout(resetQueue);
            const timeoutHandle = setTimeout(() => this.setState({ deleteQueue: "" }), 750);
            this.setState({ deleteQueue: `Quiz:${e}`, resetQueue: timeoutHandle })
            return
        }
        const { quizzes, token } = this.props.session
        const _q = quizzes[e]
        this.props.deleteQuiz(token, _q._id)
    }

    //Audrey's version it is working the other one....no idea
    deleteQuestion2(e) {
        const {questions, token} = this.props.session;
        const _q = questions[e];
        this.props.deleteQuestion(token, e);
    }


    deleteQuestion(e) {
        const { deleteQueue, resetQueue } = this.state
        if (deleteQueue != `Quest:${e}`) {
            clearTimeout(resetQueue);
            const timeoutHandle = setTimeout(() => this.setState({ deleteQueue: "" }), 750);
            this.setState({ deleteQueue: `Quest:${e}`, resetQueue: timeoutHandle });
            return
        }

        const { questions, token } = this.props.session
        const _q = questions[e]
        this.props.deleteQuestion(token, _q._id)
    }

    filterList() {
        return <div>
            <div className='flex-form gap'>
                <div className=' inline gap choice spc-between'>
                    <div className='question-search inline gap'>
                        <div className="me-auto questionText">
                            <input className="form-control " id="query" autoComplete="off" defaultValue={"$"} placeholder='$ to view all' onChange={(e) => this.setState({ query: e.target.value })} />
                        </div>
                    </div>
                </div>
                {this.filterQuestions()}
            </div>
        </div>
    }

    filterQuestions() {
        const { query, questionsIds } = this.state
        if (!query) return
        try {
            const { deleteQueue } = this.state
            const { session } = this.props;
            const { questions } = this.props.session
            let test = new RegExp(query, "i")
            let filteredQuestions = questions.filter(q => (test.test(q.question) || q.tags.includes(query)) && !questionsIds.includes(q._id) )

            return <Fragment>{filteredQuestions.map((f, index) => {
                return <Fragment>
                    <div className='uline'>
                        <div className="inline gap choice spc-between mb-4 align-items-center">
                            <div className="quizText d-flex me-auto">
                                <p className="m-0 blackText">{f.question}</p>
                            </div>
                            <button className="shadowButtons dropShadow d-flex justify-content-center" id={index} name="action"
                                onClick={(e) => this.editQuestion(e.target.value)} value={f.id}>
                                <img src={EditPen} className="trashSize align-self-center" value={index}/>
                            </button>
                            <button className={"shadowButtons dropShadow d-flex justify-content-center" + ((deleteQueue == `Quest:${index}`) ? " warning" : "")} name="delete"
                                onClick={(e) => this.handleShowModal(e.target.value, "question")} value={f.id}>
                                <img src={TrashCan} className="trashSize align-self-center" value={index}/>
                            </button>
                        </div >
                        {f.tags.map((tag, index) => (
                            <div className='tag '>
                                <div key={index}>{tag}</div>
                            </div>
                        ))}
                    </div>
                </Fragment>

            })}</Fragment>
        } catch { return }
    }

    render() {
        return (<div className='flex-from'>
            <div className="lrg-container contain">
                <div className='flex-form gap'>
                    <div className='inline gap'>
                        <h1 className="profileTitle">Quizzes</h1>
                        <a className="createButtons dropShadow" href="/admin/addQuiz">+ New</a>
                    </div>
                    {this.formQuizzes()}
                </div>
            </div>
            <div className="lrg-container contain">
                <div className='flex-form gap'>
                    <div className='inline gap'>
                        <h1 className="profileTitle">Questions</h1>
                        <a className="createButtons dropShadow" href="/admin/add">+ New</a>
                    </div>
                    {this.filterList()}
                </div>
            </div>
            {this.modal()}
        </div>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    getQuestions: userActions.getQuestions,
    getQuizzes: userActions.getQuizzes,
    deleteQuestion: userActions.deleteQuestion,
    deleteQuiz: userActions.deleteQuiz,
    editQuiz: userActions.editQuiz,
    publish: userActions.publish,
    displayError: userActions.displayError,
    error: alertActions.error
};

const connectedOverviewPage = connect(mapState, actionCreators)(OverviewPage);
export { connectedOverviewPage as OverviewPage };