import { userConstants } from "../redux/constants";
import { alertActions } from "../actions";

import env from "react-dotenv";
// const API = 'http://localhost:3001/api'
const API = 
  env?.API_Domain ||
  (window.location.origin == "http://localhost:3000"
    ? "http://localhost:3001"
    : window.location.origin) + "/api"; // sometimes forgets what env is on reroute
console.log(API);

export const userActions = {
  API,
  logout,
  removeWrong,
  resetLast,

  getAccountInfo,
  updateAccountInfo,
  confirmAccountChanges,
  signin,
  createUser,
  verifyKey,
  createAccountPassword,
  checkEmail,
  resetPassword,
  getQuiz,
  archiveAttempt,
  startANewAttempt,
  newAttempt,
  getAttempts,
  resumeAttempt,
  saveAttempt,
  submitAttempt,
  urlConformation,

  getQuizzes,
  createQuiz,
  editQuiz,
  deleteQuiz,
  publish,
  addKeys,

  getQuestions,
  createQuestion,
  editQuestion,
  deleteQuestion,

  getUploaded,

  reroute,
  displayError,

  initPayment,
  resetPayment,
  finishPayment,
  generateKey,
};

function publish(token, quizId) {
  return (dispatch) => {
    dispatch(request("trying function publish()"));
    dispatch(alertActions.clear());
    fetch(API + "/publish", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, quizId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          dispatch(alertActions.success(message));
          setTimeout(() => reroute("admin/view"), 0);
        } 
      });
  };
  function request(action) {
    return { type: userConstants.publishREQUEST, action };
  }
  // function success(users) {
  //   return { type: userConstants.publishSUCCESS, users };
  // }
  function failure(action) {
    return { type: userConstants.publishFAILURE, action };
  }
}

function initPayment(total, amount, id) {
  return (dispatch) => {
    dispatch(request("trying function initPayment()"));
    dispatch(alertActions.clear());

    fetch(API + "/charge", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ total, amount, id }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, user, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(success(message));
        if (user) dispatch(success(user));
      });
  };

  function request(action) {
    return { type: userConstants.getAccountInfoREQUEST, action };
  }
  function success(payment) {
    return { type: userConstants.getPaymentSUCCESS, payment };
  }
}

function finishPayment(token, amount, id) {
  return (dispatch) => {
    dispatch(request("trying function verifyKey()"));
    dispatch(alertActions.clear());

    fetch(API + "/generateKey", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, amount, id }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token } = data;
      });
  };
  function request(action) {
    return { type: userConstants.verifyKeyREQUEST, action };
  }
  function success(token) {
    return { type: userConstants.verifyKeySUCCESS, token };
  }
}

function resetPayment(total) {
  return (dispatch) => {
    dispatch({ type: userConstants.showPayment });
  };
}

function removeWrong() {
  return (dispatch) => {
    dispatch({ type: userConstants.removeWrong });
  };
}

function resetLast() {
  return (dispatch) => {
    dispatch({ type: userConstants.resetLast });
  };
}

function logout() {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
  };
}

function getAccountInfo(token) {
  return (dispatch) => {
    dispatch(request("trying function getAccountInfo()"));
    dispatch(alertActions.clear());

    fetch(API + "/getAccountInfo", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, user, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (user) dispatch(success(user));
      });
  };
  function request(action) {
    return { type: userConstants.getAccountInfoREQUEST, action };
  }
  function success(user) {
    return { type: userConstants.getAccountInfoSUCCESS, user };
  }
}

function updateAccountInfo(token, changes) {
  return (dispatch) => {
    dispatch(request("trying function updateAccountInfo()"));
    dispatch(alertActions.clear());

    fetch(API + "/updateAccountInfo", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, changes }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.updateAccountInfoREQUEST, action };
  }
}

function confirmAccountChanges(token) {
  return (dispatch) => {
    dispatch(request("trying function confirmAccountChanges()"));
    dispatch(alertActions.clear());

    fetch(API + "/confirmAccountChanges", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.confirmAccountChangesREQUEST, action };
  }
}

function signin(email, password) {
  return (dispatch) => {
    dispatch(request("trying to sign in"));
    dispatch(alertActions.clear());

    fetch(API + "/signin", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token, clearance } = data;
        if (token) dispatch(success(token, clearance));
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
        if (token && !clearance) reroute("confirm");
      });
  };
  function request(action) {
    return { type: userConstants.REQUEST, action };
  }
  function success(token, clearance) {
    return { type: userConstants.LOGIN, token, clearance };
  }
}

function createUser(firstName, lastName, email, department) {
  console.log("Useractions createUser")
  return (dispatch) => {
    dispatch(request("trying function createUser()"));
    dispatch(alertActions.clear());

    fetch(API + "/createUser", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ firstName, lastName, email, department }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token } = data;
        if (token) {
          dispatch(success(token));
          setTimeout(() => reroute("confirm"), 0);
        }
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.REQUEST, action };
  }
  function success(token) {
    return { type: userConstants.CREATE, token };
  }
}

function verifyKey(token, code) {
  return (dispatch) => {
    dispatch(request("trying function verifyKey()"));
    dispatch(alertActions.clear());

    fetch(API + "/verifyKey", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, keyCode: code }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token } = data;
        if (token) {
          dispatch(success(token));
          this.setTimeout(() => reroute("confirm"), 10);
        }
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.verifyKeyREQUEST, action };
  }
  function success(token) {
    return { type: userConstants.verifyKeySUCCESS, token };
  }
}


function addKeys(token, urlToken) {
  return (dispatch) => {
    dispatch(request("trying function addKeys()"));
    dispatch(alertActions.clear());

    fetch(API + "/addKeys", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, urlToken }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) {
          console.log("Add keys running")
          dispatch(alertActions.success(message));
        } 
      });
  };
  function request(action) {
    return { type: userConstants.verifyKeyREQUEST, action };
  }
}


function generateKey(token, amount, id, email) {
  return (dispatch) => {
    dispatch(request("trying function verifyKey()"));
    dispatch(alertActions.clear());

    fetch(API + "/generateKey", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, amount, id, email }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token, univUser } = data;
        if(message) {
          dispatch(alertActions.success(message));
        }
        if(univUser) {
          setTimeout(() => reroute("user"), 0);
        }
        if (err) {
          dispatch(alertActions.error(err));
        }
      });
  };
  function request(action) {
    return { type: userConstants.verifyKeyREQUEST, action };
  }
  function success(token) {
    return { type: userConstants.verifyKeySUCCESS, token };
  }
}

function createAccountPassword(token, password) {
  return (dispatch) => {
    dispatch(request("trying function createAccountPassword()"));
    dispatch(alertActions.clear());

    fetch(API + "/createAccountPassword", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, token, clearance } = data;
        if (token) dispatch(success(token, clearance));
        if (err) {
          setTimeout(() => reroute("signin"), 1500);
          dispatch(alertActions.error(err));
        }
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.createAccountPasswordREQUEST, action };
  }
  function success(token, clearance) {
    return {
      type: userConstants.createAccountPasswordSUCCESS,
      token,
      clearance,
    };
  }
}

function checkEmail(email) {
  return (dispatch) => {
    dispatch(request("trying function checkEmail()"));
    dispatch(alertActions.clear());

    fetch(API + "/checkEmail", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.checkEmailREQUEST, action };
  }
}

function resetPassword(token, password) {
  return (dispatch) => {
    dispatch(request("trying function resetPassword()"));
    dispatch(alertActions.clear());

    fetch(API + "/resetPassword", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) {
          setTimeout(() => reroute("signin"), 1500);
          dispatch(alertActions.success(message));
        }
      });
  };
  function request(action) {
    return { type: userConstants.resetPasswordREQUEST, action };
  }
}

function getQuiz(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function getQuiz()"));
    dispatch(alertActions.clear());

    fetch(API + "/getQuiz", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.getQuizREQUEST, action };
  }
  function failure(action) {
    return { type: userConstants.getQuizFAILURE, action };
  }
}

function archiveAttempt(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function archiveAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/archiveAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) {
          dispatch(alertActions.success(message));
          dispatch(success(attemptId));
        }
      });
  };
  function request(action) {
    return { type: userConstants.archiveAttemptREQUEST, action };
  }
  function success(attemptId) {
    return { type: userConstants.archiveAttemptSUCCESS, attemptId };
  }
}

function startANewAttempt(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function startANewAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/startANewAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId }),
    })
    .then((res) => res.json())
    .then((data) => {
      const { err, attempt } = data;
      if (err) {
        dispatch(alertActions.error(err));
      }
      if (attempt) {
        setTimeout(() => reroute("user/quiz?A=" + attemptId), 10);
      } 
    });
  };
  function request(action) {
    return { type: userConstants.startANewAttemptREQUEST, action };
  }
}


function newAttempt(token, keyToken) {
  return (dispatch) => {
    dispatch(request("trying function newAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/newAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, keyToken }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, attempt } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (attempt) {
          dispatch(success(attempt));
          setTimeout(() => reroute("user"), 500);
        } 
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.newAttemptREQUEST, action };
  }
  function success(attempt) {
    return { type: userConstants.newAttemptSUCCESS, attempt };
  }
  function failure(action) {
    return { type: userConstants.newAttemptFAILURE, action };
  }
}

function getAttempts(token) {
  return (dispatch) => {
    dispatch(request("trying function getAttempts()"));

    fetch(API + "/getAttempts", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, attempts } = data;
        if (err) {
          dispatch(alertActions.error(err));
        }
        if (attempts) dispatch(success(attempts));
      });
  };
  function request(action) {
    return { type: userConstants.getAttemptsREQUEST, action };
  }
  function success(attempts) {
    return { type: userConstants.getAttemptsSUCCESS, attempts };
  }
}

function resumeAttempt(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function resumeAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/resumeAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, attempt } = data;
        if (err) {
          dispatch(alertActions.error(err));
          setTimeout((err) => dispatch(alertActions.error(err)), 100);
          reroute("user");
        }
        if (attempt) dispatch(success(attempt));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.resumeAttemptREQUEST, action };
  }
  function success(attempt) {
    return { type: userConstants.resumeAttemptSUCCESS, attempt };
  }
}

function saveAttempt(token, attemptId, ans) {
  return (dispatch) => {
    dispatch(request("trying function saveAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/saveAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId, ans }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, answers, wrong, wrongFile, lastId, retry } = data;
        if (err) dispatch(alertActions.error(err));
        if (wrong) dispatch(failure(wrong, wrongFile, retry, lastId));
        if (answers) dispatch(success(answers));
        if (message) dispatch(alertActions.success(message));
      });
  };
  function request(action) {
    return { type: userConstants.saveAttemptREQUEST, action };
  }
  function success(answers) {
    return { type: userConstants.saveAttemptSUCCESS, answers };
  }
  function failure(wrong, wrongFile, retry, lastId) {
    return {
      type: userConstants.wrongQuestionAnswer,
      wrong,
      wrongFile,
      retry,
      lastId,
    };
  }
}

function submitAttempt(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function submitAttempt()"));
    dispatch(alertActions.clear());

    fetch(API + "/submitAttempt", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, attemptId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, quizName, score, failedFromWrongAnswers, passed } = data;
        if (err) dispatch(alertActions.error(err));
        if (score !== null)
          dispatch(success(score, quizName, failedFromWrongAnswers, passed));
      });
  };
  function request(action) {
    return { type: userConstants.submitAttemptREQUEST, action };
  }
  function success(score, quizName, failedFromWrongAnswers, passed) {
    return {
      type: userConstants.submitAttemptSUCCESS,
      score,
      quizName,
      failedFromWrongAnswers,
      passed,
    };
  }
}

function getQuizzes(token, info) {
  return (dispatch) => {
    dispatch(request("trying function getQuizzes()"));

    fetch(API + "/getQuizzes", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, info }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, quizzes } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (quizzes) dispatch(success(quizzes));
      });
  };
  function request(action) {
    return { type: userConstants.getQuizzesREQUEST, action };
  }
  function success(quizzes) {
    return { type: userConstants.getQuizzesSUCCESS, quizzes };
  }
  function failure(action) {
    return { type: userConstants.getQuizzesFAILURE, action };
  }
}

function createQuiz(token, info) {
  return (dispatch) => {
    dispatch(request("trying function createQuiz()"));
    dispatch(alertActions.clear());

    fetch(API + "/createQuiz", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, info }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          dispatch(alertActions.success(message));
          setTimeout(() => reroute("admin/view"), 500);
        }
      });
  };
  function request(action) {
    return { type: userConstants.createQuizREQUEST, action };
  }
  function failure(action) {
    return { type: userConstants.createQuizFAILURE, action };
  }
}

function editQuiz(token, quizId, changes) {
  return (dispatch) => {
    dispatch(request("trying function editQuiz()"));

    fetch(API + "/editQuiz", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, quizId, changes }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) dispatch(alertActions.error(err));
        if (message) {
          dispatch(alertActions.success(message));
          setTimeout(() => reroute("admin/view"), 750);
        }
      });
  };
  function request(action) {
    return { type: userConstants.editQuizREQUEST, action };
  }
}

function deleteQuiz(token, quizId) {
  return (dispatch) => {
    dispatch(request("trying function deleteQuiz()"));
    dispatch(alertActions.clear());

    fetch(API + "/deleteQuiz", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, quizId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          dispatch(alertActions.success(message));
          dispatch(success(quizId));
        }
      });
  };

  function request(action) {
    return { type: userConstants.deleteQuizREQUEST, action };
  }
  function success(quizId) {
    return { type: userConstants.deleteQuizSUCCESS, quizId };
  }
  function failure(action) {
    return { type: userConstants.deleteQuizFAILURE, action };
  }
}

function getQuestions(token) {
  return (dispatch) => {
    dispatch(request("trying function getQuestions()"));

    fetch(API + "/getQuestions", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, questions } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (questions) dispatch(success(questions));
      });
  };

  function request(action) {
    return { type: userConstants.getQuestionsREQUEST, action };
  }
  function success(questions) {
    return { type: userConstants.getQuestionsSUCCESS, questions };
  }
  function failure(action) {
    return { type: userConstants.getQuestionsFAILURE, action };
  }
}

function createQuestion(token, info) {
  return (dispatch) => {
    dispatch(request("trying function createQuestion()"));
    dispatch(alertActions.clear());

    fetch(API + "/createQuestion", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, info }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          reroute("admin/view");
        }
      });
  };
  function request(action) {
    return { type: userConstants.createQuestionREQUEST, action };
  }
  function failure(action) {
    return { type: userConstants.createQuestionFAILURE, action };
  }
}

function editQuestion(token, questionId, changes) {
  return (dispatch) => {
    dispatch(request("trying function editQuestion()"));
    dispatch(alertActions.clear());

    fetch(API + "/editQuestion", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, questionId, changes }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          dispatch(alertActions.success(message));
          setTimeout(() => reroute("admin/view"), 500);
        }
      });
  };

  function request(action) {
    return { type: userConstants.editQuestionREQUEST, action };
  }
  function failure(action) {
    return { type: userConstants.editQuestionFAILURE, action };
  }
}

function deleteQuestion(token, questionId) {
  return (dispatch) => {
    dispatch(request("trying function deleteQuestion()"));
    dispatch(alertActions.clear());

    fetch(API + "/deleteQuestion", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, questionId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message } = data;
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (message) {
          dispatch(alertActions.success(message));
          dispatch(success(questionId));
        }
      });
  };

  function request(action) {
    return { type: userConstants.deleteQuestionREQUEST, action };
  }
  function success(questionId) {
    return { type: userConstants.deleteQuestionSUCCESS, questionId };
  }
  function failure(action) {
    return { type: userConstants.deleteQuestionFAILURE, action };
  }
}

function getUploaded() {
  return (dispatch) => {
    dispatch(request("trying function getUploaded()"));
    dispatch(alertActions.clear());

    fetch(API + "/getUploaded", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        const { files } = data;
        if (files) dispatch(success(files));
      });
  };

  function request(action) {
    return { type: userConstants.getUploadedREQUEST, action };
  }
  function success(questionId) {
    return { type: userConstants.getUploadedSUCCESS, questionId };
  }
}

function urlConformation(token, info) {
  return (dispatch) => {
    dispatch(request("trying function urlConformation()"));
    dispatch(alertActions.clear());
    fetch(API + "/urlConformation", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Content-Security-Policy': "default-src 'self';",
         
        'X-Content-Type-Options': 'nosniff',
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        'Permissions-Policy': 'accelerometer=(), camera=(), geolocation=(), microphone=(), payment=(), usb=()'
      },
      body: JSON.stringify({ token, info }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, quiz } = data;
        if (err) {
          dispatch(alertActions.error(err));
          setTimeout(() => reroute("user"), 1000);
        }
        if (quiz) dispatch(success(quiz));
      });
  };
  function request(action) {
    return { type: userConstants.urlConformationREQUEST, action };
  }
  function success(quiz) {
    return { type: userConstants.urlConformationSUCCESS, quiz };
  }
}


//Code for documentation and onboarding
/*
function exampleAction(token, attemptId) {
  return (dispatch) => {
    dispatch(request("trying function exampleAction()"));
    dispatch(alertActions.clear());
    fetch(API + "/exampleAction", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, attemptId }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { err, message, users } = data;
        console.log(users)
        if (err) {
          dispatch(alertActions.error(err));
          dispatch(failure());
        }
        if (users) dispatch(success(users));
      });
  };
  function request(action) {
    return { type: userConstants.exampleActionREQUEST, action };
  }
  function success(users) {
    return { type: userConstants.exampleActionSUCCESS, users };
  }
  function failure(action) {
    return { type: userConstants.exampleActionFAILURE, action };
  }
}
*/

function reroute(location) {
  window.location.assign(`${window.location.origin}/${location}`);
}

function displayError(err) {
  return (dispatch) => {
    dispatch(request("trying function displayError()"));
    dispatch(alertActions.clear());
    if(err) {
      dispatch(alertActions.error(err));
    }
    function request(action) {
      return { type: userConstants.displayErrorRequest, action };
    }
  };
}