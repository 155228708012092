import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';
import { FileViewer } from '../../Components';

class TakingQuizPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAnswers: new Set()
        }

        this.createChoices = this.createChoices.bind(this);
        this.setChoices = this.setChoices.bind(this);
    }

    componentDidMount() {
        const { token } = this.props.session

        const urlParams = new URLSearchParams(window.location.search);

        try {
            const myParam = urlParams.get('A');
            if (!myParam) throw ""
            this.props.resumeAttempt(token, myParam)
        } catch {
            userActions.reroute("user")
        }
    }

    setChoices(val) {
        const { currentAnswers } = this.state
        const { answers, questions } = this.props.session.attempt
        const current = questions[answers.length]
        const multipleChoice = current.radioButton

        var _ = currentAnswers
        if (multipleChoice) {
            if (!_) _ = new Set()
            if (_.has(val)) _.delete(val)
            else _.add(val)
        } else _ = new Set([val])

        this.setState({ currentAnswers: _ })
    }

    createChoices() {
        const { currentAnswers } = this.state
        const { answers, questions } = this.props.session.attempt
        const questionIndex = answers.length
        const current = questions[questionIndex]
        const choices = current.choices.map((a, index) =>
            <center>
                <label className="size-lrg-questions inline gap contain choice selectable whiteText questionsMax" id={index} htmlFor={`answer-${index}`}>
                    <div>
                    <input type="checkbox" className={`checkbox-${current.radioButton ? "square" : "round"}`}
                        checked={currentAnswers?.has(`${index}`) || false} id={`answer-${index}`}
                        onChange={(e) => this.setChoices(e.target.id.split("-")[1])} />
                    </div>
                    {a}
                </label>
            </center>
        )
        if (current.scrambleChoices) return this.shuffle(choices);
        return choices;
    }

    saveAttempt() {
        this.props.alertClear()
        const { currentAnswers } = this.state;
        if ([...currentAnswers].length == 0) {
            setTimeout(() => this.props.alertError("no answer given please enter one"), 0)
            return
        }
        const { token, attempt } = this.props.session
        const { answers, questions, attemptId } = attempt
        const questionIndex = answers.length
        const current = questions[questionIndex]
        this.props.saveAttempt(token, attemptId, { questionId: current.questionId, answer: [...currentAnswers] })
        this.setState({ currentAnswers: new Set() })
        this.scrollToTop()
    }

    shuffle(array) {
        let currentIndex = array.length, randomIndex;

        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    wrongButtonPress() {
        const { retry, attempt, lastId } = this.props.session;
        const { answers } = attempt;

        if (retry > 0) {
            this.setState({ currentAnswers: new Set(answers.filter(a => a?.questionId == lastId)[0].answer) })
            this.props.resetLast()
        }
        else return this.props.removeWrong()
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can change this to 'auto' if you prefer an instant scroll
        });
    };
    
    render() {
        const { session } = this.props;
        const { attempt } = session;
        if (!attempt) {
            return <div className="med-container">
                <center>
                    <div className="size-lrg">
                        <h1>Attempting to Resume Quiz</h1>
                    </div>
                </center>
            </div>
        }

        const { answers, questions } = session.attempt
        const questionIndex = answers.length

        const finishedQuiz = !session?.retry && !session?.wrong && answers.length == questions.length
        if (!session?.retry && !session?.wrong && answers.length == questions.length && typeof attempt.score === 'undefined') {
            const { token } = session
            this.props.submitAttempt(token, attempt.attemptId)
        }
        const current = questions[questionIndex]

        return (<Fragment>
            <form className="lrg-container contain">
                {/* taking Quiz */}
                {!finishedQuiz ? <Fragment>
                    {/* Questions View */}
                    {!session.wrong ? <Fragment>
                        <center>
                            <FileViewer file={current.file} />
                                <h1 className="questionNumber m-0">{attempt.answers.length + 1}</h1>
                                <label htmlFor="question" name="question" id="question" className="col-10 question">{current.question.split('\n').map((a, index) => (<Fragment>{index > 0 && <br />}{a}</Fragment>))}</label>
                        </center>

                        <div className="flex-form gap">
                            {this.createChoices()}
                        </div>
                        <br />
                        <center>
                            <button type='button' className="size-sml" onClick={() => this.saveAttempt()}>Submit</button>
                        </center>
                        <progress value={answers.length} max={questions.length}></progress>
                        <center>{answers.length + 1} / {questions.length}</center>
                    </Fragment> : <Fragment>
                        {/* Showing Wrong answer View */}
                        <center>
                            Hint:
                            <div className="size-lrg">
                                <label className="question whiteText">
                                    {session.wrong.split('\n').map((a, index) => (<Fragment>{index > 0 && <br />}{a}</Fragment>))}
                                </label>
                                {session?.wrongFile && <Fragment>
                                    <br />
                                    <FileViewer file={session.wrongFile} />
                                </Fragment>}
                            </div>
                        </center>
                        {session?.retry > 0 && `Attempts Remaining: ${session?.retry} `}
                        <center>
                            <button type="button" className="size-sml" onClick={() => this.wrongButtonPress()}>{session?.retry > 0 ? `Return` : answers.length == questions.length ? "End Quiz" : "Next Question"}</button>
                        </center>
                    </Fragment>}
                </Fragment> : <Fragment>
                    {/* End of quiz */}
                    <label>Quiz Results</label>
                    <center>
                        <div className="size-lrg whiteText">
                            Quiz Name: <strong>{attempt.quizName}</strong> <br />
                            Your score: <strong>{parseFloat(attempt.score || 0).toFixed(0)}%</strong>
                            {attempt.failedFromWrongAnswers && <Fragment>
                                <br />
                                <strong>Ejected From Quiz</strong>: too many wrong answers
                            </Fragment>}
                            {attempt.passed && <Fragment>
                                <br />
                                Congratulations on passing the quiz!
                                {attempt.passed == 'email' && <Fragment>
                                    <br />
                                    A confirmation email has been sent to your email address.
                                </Fragment>}
                            </Fragment>}
                        </div>
                    </center>
                    <br />
                    <center>
                        <button type='button' className="size-sml" onClick={() => this.props.reroute('user')}>Return</button>
                    </center>
                </Fragment>}
            </form>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    reroute: userActions.reroute,
    resumeAttempt: userActions.resumeAttempt,
    saveAttempt: userActions.saveAttempt,
    submitAttempt: userActions.submitAttempt,
    resetLast: userActions.resetLast,
    removeWrong: userActions.removeWrong,
    alertError: alertActions.error,
    alertClear: alertActions.clear
};

const connectedTakingQuizPage = connect(mapState, actionCreators)(TakingQuizPage);
export { connectedTakingQuizPage as TakingQuizPage };