import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { userConstants } from '../../redux/constants';

class HomePage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
    }

    render() {
        return (<Fragment>
            <div>
            <div className="med-container-long">
                <div>
                    <h1 className="fw-bold">Convenient Online Training<br /></h1>
                    <br/>
                    <h2 className="text-left">If your organization utilizes multi-passenger vans (8 or more passengers), Motor Pool Services has a training module to educate drivers on the unique operating characteristics of these vans and overall best driving practices.
                    </h2>
                </div>
                <div>
                    <br/>
                    <br/>
                    <center>
                    {!this.props.session.token?<a className='size-lrg' href='/signin'>Begin Training</a>:<a className='size-lrg' href='/user'>Begin Training</a>}
                    </center>
                </div>
               
            </div>
            </div>
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    logout: userActions.logout
};

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };