import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class SignupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            department: ''
        }
        this.createAccount = this.createAccount.bind(this);
    }

    createAccount(e) {
        e.preventDefault()
        const { firstname, lastname, email, department } = this.state;
        this.props.createUser(firstname, lastname, email, department)
    }

    render() {
        const { clearance } = this.props.session;
        if (clearance !== "unknown" && clearance !== "") {
            userActions.reroute("user")
        }
        return (<Fragment>
            <form className="lrg-container" onSubmit={this.createAccount}>
                <p className="blackText">Please type your first and last name, department name, and email in the spaces below so that Motor Pool Services can assign certificates.</p>

                <center >
                    <div className='size-lrg flex-form whiteText'>
                        <div className='inline'>
                            <label htmlFor="inputFirst" className="col-4">First Name</label>
                            <input className="form-control" onChange={(e) => this.setState({ firstname: e.target.value })} required />
                        </div>
                        <div className='inline'>
                            <label htmlFor="inputLast" className="col-4">Last Name</label>
                            <input className="form-control" onChange={(e) => this.setState({ lastname: e.target.value })} required />
                        </div>
                        <div className='inline'>
                            <label htmlFor="inputDepartment" className="col-4">Department Name</label>
                            <input className="form-control" onChange={(e) => this.setState({ department: e.target.value })} required />
                        </div>
                        <div className='inline'>
                            <label htmlFor="inputEmail" className="col-4">Email</label>
                            <input type="email" className="form-control" onChange={(e) => this.setState({ email: e.target.value })} required />
                        </div>
                    </div>
                </center>
                <center>
                    <button className='size-sml'>Sign Up</button>
                </center>
            </form>
        </Fragment >);
    }
}

function mapState(state) {
    const { session } = state
    return { session };
}

const actionCreators = {
    createUser: userActions.createUser
};

const connectedSignupPage = connect(mapState, actionCreators)(SignupPage);
export { connectedSignupPage as SignupPage };