import { alertConstants } from '../redux/constants';

export const alertActions = {
    success,
    warning,
    error,
    clear
};

function success(message) {
    switch (typeof message) {
        case "string":
            return { type: alertConstants.SUCCESS, message };
        case "object":
            return { type: alertConstants.SUCCESS, message: `${message.name}: ${message.message}` };
        default:
            return { type: alertConstants.SUCCESS, message: JSON.stringify(message) };
    }
}

function warning(message) {
    switch (typeof message) {
        case "string":
            return { type: alertConstants.WARNING, message };
        case "object":
            return { type: alertConstants.WARNING, message: `${message.name}: ${message.message}` };
        default:
            return { type: alertConstants.WARNING, message: JSON.stringify(message) };
    }
}

function error(message) {
    switch (typeof message) {
        case "string":
            return { type: alertConstants.ERROR, message };
        case "object":
            return { type: alertConstants.ERROR, message: `${message.name}: ${message.message}` };
        default:
            return { type: alertConstants.ERROR, message: JSON.stringify(message) };
    }
}

function clear() {
    return { type: alertConstants.CLEAR };
}