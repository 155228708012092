import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';

class ConfirmPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            urlToken: new URLSearchParams(window.location.search).get('t') || '',
            password: ['', '']
        }
        this.submitCode = this.submitCode.bind(this);
        this.createPassword = this.createPassword.bind(this);
    }

    submitCode(e) {
        e.preventDefault()
        const { code, urlToken } = this.state;
        const { token } = this.props.session;
        if (!code) return;
        this.props.verifyKey(urlToken || token, code);
    }

    createPassword(e) {
        e.preventDefault()
        const { password, urlToken } = this.state;
        const { token } = this.props.session;
        if (password[0] !== password[1]) return;
        this.props.createAccountPassword(urlToken || token, password[0]);
    }

    render() {
        const { clearance, pass } = this.props.session;
        if (clearance !== "unknown")
            userActions.reroute("user")

        const { password, urlToken } = this.state;

        const createPassword = pass || !!urlToken;
        return (<Fragment>
            {!createPassword ? <Fragment>
                <form className="med-container" onSubmit={this.submitCode}>
                    <p className='blackText'>Check your email for the verification code</p>
                    <center>
                        <div className="size-lrg blocky">
                            <label htmlFor="inputEmail">Verification Code</label>
                            <input type="text" onChange={(e) => this.setState({ code: e.target.value })} required />
                        </div>
                    </center>
                    <center>
                        <button className="size-sml">Submit</button>
                    </center>
                </form>
            </Fragment> : <Fragment>
                <form className="med-container" onSubmit={this.createPassword}>
                    <p className='blackText'>Create your password</p>
                    <center>
                        <div className="size-lrg blocky">
                            <label htmlFor="inputEmail">Password</label>
                            <input type="password" onChange={(e) => this.setState({ password: [e.target.value, password[1]] })} required />

                            <label htmlFor="inputEmail">Confirm Password</label>

                            <input type="password" onChange={(e) => this.setState({ password: [password[0], e.target.value] })} required />

                            {password[0].length > 0 && password[1].length > 0 && password[0] != password[1] &&
                                <div><br/> <span className='important whiteText'>*Passwords must match*</span> </div>
                            }
                        </div>
                    </center>
                    <center>
                        <button className='size-sml'>Submit</button>
                    </center>
                </form>
            </Fragment>}
        </Fragment>);
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    signin: userActions.signin,
    verifyKey: userActions.verifyKey,
    createAccountPassword: userActions.createAccountPassword,
};

const connectedConfirmPage = connect(mapState, actionCreators)(ConfirmPage);
export { connectedConfirmPage as ConfirmPage };