import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../actions';
import { FileViewer, MediaUpload, QuestionPreview } from '../../Components';
import { AiFillEye } from 'react-icons/ai'
import ArrowDown from '../../images/ArrowDown.svg';
import ArrowUp from '../../images/ArrowUp.svg';

class EditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editQuestion: true,
            questionId: "",
            question: "",
            file: "",
            failAt: 0,
            choices: [],
            answers: new Set(),
            qIndex: 0,
            wrong: "",
            keywords: [],
            wrongFile: "",
            name: "",
            Qattempt: 1,
            QuestAttempt: 2,
            questionsIds: [],
            questionsPerQuiz: 0,
            scrambleQuestions: false,
            cost: 0,
            RedoIncrese: false,
            discount: 0,
            quizId: "",
            viewingQuestion: "",
            passing: 70,
            description: '',
            addingNew: false,
            tags: [],
            tag: ""
        }

        this.generateQuestion = this.generateQuestion.bind(this);
        this.editQuestion = this.editQuestion.bind(this);
        this.editQuiz = this.editQuiz.bind(this);
    }

    componentDidMount() {
        if (this.props.session.clearance != "admin")
            userActions.reroute("admin");

        const urlParams = new URLSearchParams(window.location.search);

        try {
            const myParam = urlParams.get('Q');
            let _ = myParam.split("-")
            let q = this.props.session[_[0]][_[1]]
            // questions
            if (_[0] == "questions") this.setState({
                questionId: q._id,
                editQuestion: _[0] == "questions",
                question: q.question,
                file: q.file || "",
                choices: q.choices,
                answers: new Set(q.answers),
                qIndex: parseInt(_[1]),
                wrong: q.wrong,
                wrongFile: q.wrongFile,
                tags: q.tags
            })// quiz
            else this.setState({
                quizId: q._id,
                editQuestion: _[0] == "questions",
                name: q.name,
                qIndex: parseInt(_[1]),
                questionsIds: q.questionsIds,
                questionsPerQuiz: q.questionsPerQuiz,
                scrambleQuestions: q.scrambleQuestions,
                cost: q.cost,
                discount: q.discount,
                Qattempt: q.quizAttempts || 1,
                failAt: q.quitQuiz || 0,
                QuestAttempt: q.questionAttempts || 2,
                RedoIncrese: q.nthAttemptImprove || false,
                passing: q.passingPercent || 70,
                description: q.description || ''
            })
        } catch {
            userActions.reroute("admin/view")
        }
    }

    generateQuestion() {
        let { choices, answers} = this.state;

        var ql = choices.map((q, index) => {
            return <center>
                <label className="inline mb-2 gap choice" htmlFor={index}>
                    <div>
                    <input type="checkbox" id={index} className={`checkbox-${answers.size > 1 ? "square" : "round"}`} autoComplete="off" checked={answers.has(`${index}`)} onChange={(e) => {
                        if (answers.has(e.target.id)) answers.delete(e.target.id);
                        else answers.add(e.target.id);
                        this.setState({ answers: answers });
                    }} /> </div>

                    <input className="form-control" id={index} value={q} required autoFocus autoComplete="off" onChange={(e) => {
                        if (e.target.value === "") {
                            answers = new Set([...answers].map(a => {
                                if (e.target.id < a) return `${a - 1}`
                                else if (e.target.id == a) return -1
                                return a
                            }).filter(a => a !== -1));
                            choices.splice(e.target.id, 1)
                        } else choices[e.target.id] = e.target.value
                        this.setState({ choices: choices, answers: answers })
                    }} />
                </label>
            </center>
        })

        ql.push(<center>
            <h1 className="fs-5 align-left ms-4">New Answer</h1>
            <label className="inline gap choice">
                <div className={`checkbox-${answers.size > 1 ? "square" : "round"}`} />
                <input className="form-control" id={choices.length} value="" autoComplete="off" onChange={(e) => {
                    choices.push(e.target.value)
                    this.setState({ choices: choices })
                }} placeholder="Type here to add a new answer" />
            </label>
        </center>
        )

        return ql
    }


    addTag() {
        const {tag, tags} = this.state
        if(tag != "") {
            tags.push(tag);
            this.setState({ tag: "", tags: tags })
        }
    }

    editQuestion(e) {
        this.props.clearAlerts()

        e.preventDefault()
        const { question, file, choices, answers, wrong, questionId, wrongFile, tags } = this.state;
        const { token } = this.props.session;

        if (answers.size > 0) {
            this.props.editQuestion(token, questionId, {
                file,
                question,
                choices,
                answers: [...answers],
                wrong,
                wrongFile,
                scrambleChoices: false,
                tags
            })
        } else {
            setTimeout(() => this.props.alertError("Correct answer needed to save question. Click one of the radio buttons under Choices to select a correct answer."), 0)
        }
    }

    // this function is here so the quiz is not submitted when they hit enter in the search field
    stop = (e) => e.preventDefault()

    removeQuestion(e) {
        const { questionsIds } = this.state
        questionsIds.splice(e, 1)
        this.setState({ questionsIds: questionsIds })
    }

    addAllFlitered() {
        const { query, questionsIds } = this.state
        if (!query) return
        const { questions } = this.props.session

        let test = new RegExp(query, "i")

        const filtered = questions.filter(q => (test.test(q.question) || q.tags.includes(query)) && !questionsIds.includes(q._id)).map(i => i._id)
        this.setState({ questionsIds: [...questionsIds, ...filtered] })
    }

    filterQuestions() {
        const { query, questionsIds } = this.state
        if (!query) return
        try {
            const { deleteQueue } = this.state
            const { session } = this.props;
            const { questions } = this.props.session
            let test = new RegExp(query, "i")
            let filteredQuestions = questions.filter(q => (test.test(q.question) || q.tags.includes(query)) && !questionsIds.includes(q._id) )

            return <Fragment>{filteredQuestions.map((f, index) => {
                return <Fragment>
                    <div className='uline'>
                        <div className="inline gap choice spc-between mb-4 align-items-center">
                            <div className="quizText d-flex me-auto">
                                <p className="m-0 blackText">{f.question}</p>
                            </div>
                            <button type="button" className="createButtons d-flex" id={f._id} name="action"
                                onClick={(e) => this.viewQuestion(f._id)} value={f._id}>
                                <p className="fs-5 m-0 align-self-center"> View </p>
                            </button>
                            <button type="button" className="quiz" name="delete"
                                onClick={(e) => this.setState({ questionsIds: [...questionsIds, e.target.value] })} value={f._id}>
                                Add
                            </button>
                        </div >
                        {f.tags.map((tag, index) => (
                            <div className='tag '>
                                <div key={index}>{tag}</div>
                            </div>
                        ))}
                    </div>
                </Fragment>

            })}</Fragment>
        } catch { return }
    }

    viewQuestion(e) {
        this.setState({ viewingQuestion: e })
    }

    deleteTag(e) {
        const { tags } = this.state
        tags.splice(e, 1)
        this.setState({ tags: tags });
    }

    displayTags() {
        let { tags } = this.state;
        return tags.map((tag, index) => (
            <Fragment>
                <div className='tags-container tags-list'>
                    <div key={index}>{tag}</div>
                    <button type="button" className="createButtons d-flex" name="action"
                        onClick={(e) => this.deleteTag(e.target.value)} value={index}>
                            Delete
                    </button>
                </div>
            </Fragment>
        ))
    }

    moveQuestion(qIndex, direction) {
        const { questionsIds } = this.state;
        if(direction > 0) {
            if(qIndex + 1 < questionsIds.length) {
                [questionsIds[qIndex + 1], questionsIds[qIndex]] = [questionsIds[qIndex], questionsIds[qIndex + 1]];
            }
        } else {
            if(qIndex - 1 > -1) {
                [questionsIds[qIndex - 1], questionsIds[qIndex]] = [questionsIds[qIndex], questionsIds[qIndex - 1]];
            }
        }
        this.setState({ questionsIds: questionsIds})
    }

    findQuestions() {
        const { questionsIds } = this.state;
        if (!questionsIds.length) return;

        const { questions } = this.props.session
        var search = JSON.parse(JSON.stringify(questionsIds))
        let hashTable = JSON.parse(`{${search.map((s, index) => `"${s}":${index}`).join(",")}}`)
        search = new Set(search)
        let _questions = JSON.parse(JSON.stringify(questionsIds))

        for (var i = 0; i < questions.length; i++) {
            if (!search.size) break;
            if (search.has(questions[i]._id)) {
                _questions[hashTable[questions[i]._id]] = questions[i]
                search.delete(questions[i]._id)
            }
        }

        let deletedQuestions = _questions.filter(q => typeof q === "string")
        if (deletedQuestions.length > 0) {
            setTimeout(() => this.setState({ questionsIds: questionsIds.filter(q => !deletedQuestions.includes(q)) }), 0)
            return
        }

        return _questions.map((q, index) => {
            return ( <Fragment>
                <div className='uline'>
                    <div className="inline gap choice spc-between mb-4 align-items-center">
                        <div>
                            <button type="button" className="arrow-btn d-flex" id={q._id} name="action"
                                onClick={(e) => this.moveQuestion(index, -1)}>
                                <img src={ArrowUp} className="arrow align-self-center" value={index}/>
                            </button>
                            <button type="button" className="arrow-btn d-flex" id={q._id} name="action"
                                onClick={(e) => this.moveQuestion(index, 1)}>
                                <img src={ArrowDown} className="arrow align-self-center" value={index}/>
                            </button>
                        </div>
                        <div className="quizText d-flex me-auto">
                            <p className="m-0">Question #{index + 1}: {q.question}</p>
                        </div>
                        <button type="button" className="createButtons d-flex"
                            onClick={(e) => this.viewQuestion(q._id)} value={q._id}>
                        <p className="fs-5 m-0 align-self-center"> View </p>
                        </button>
                        <button type="button" className="quiz"
                            onClick={(e) => this.removeQuestion(e.target.value)} value={index}>
                            Remove
                        </button>
                    </div>
                    {q.tags.map((tag, index) => (
                        <div className='tag '>
                            <div key={index}>{tag}</div>
                        </div>
                    ))}
                </div>
            </Fragment>
            )
        })
    }

    editQuiz() {
        this.props.clearAlerts()
        const { name, questionsIds, questionsPerQuiz, scrambleQuestions, cost, discount, Qattempt, failAt, QuestAttempt, RedoIncrese, passing, quizId, description } = this.state;
        const { token } = this.props.session
        this.props.editQuiz(token, quizId, {
            name, questionsIds, questionsPerQuiz, scrambleQuestions, cost, discount, description,
            quitQuiz: failAt,
            quizAttempts: Qattempt,
            questionAttempts: QuestAttempt,
            nthAttemptImprove: RedoIncrese,
            passingPercent: passing,
        })
    }

    render() {
        // -------------------------------------------------------------------  Editing Question ------------------------------------------------------------------- 
        if (this.state.editQuestion) {
            const { session } = this.props
            const { question, wrong, file, qIndex, wrongFile, scrambleChoices, tag } = this.state;
            return (<Fragment>
                <form className="creation" onSubmit={(e) => this.editQuestion(e)}>
                        <label className="p-0 mb-4 row fs-1">
                            <p>Question Number : {qIndex +1} </p>
                        </label>
                    <div className="lrg-container contain border border-2 border-black">
                        <p className=" fs-1">Question Name</p>
                        <textarea value={question} className="form-control" id="inputQuestion" name="inputQuestion" rows={question.split("\n").length} required autoComplete="off" onChange={(e) => this.setState({ question: e.target.value })} />
                        <br />
                        <div style={{ position: 'relative', textAlign: "left", marginLeft: '5%' }}>
                            <MediaUpload updateURL={(e) => this.setState({ file: `${userActions.API.split('/api')[0]}${e}` })} />
                            <div style={{ display: 'inline-block', width: '45%', top: '0px', position: 'absolute' }}>
                                <center>URL</center>
                                <input className="form-control" id="inputQuestion" name="inputQuestion" autoComplete="off"
                                    placeholder='https://somedomain.com/filename.extention' value={file} onChange={(e) => this.setState({ file: e.target.value })} />
                            </div>
                        </div>
                        <br />
                        <div className='justify-content-center'>
                            <FileViewer file={file} showSupported />
                        </div>
                    </div>
                    <div className="lrg-container contain border border-2 border-black">
                        <p className="fs-1">Choices</p>
                        <div className="flex-form gap">
                            <h5>Select the checkboxes for the correct answers on the left.</h5>
                            <div>
                                {this.generateQuestion()}
                            </div>
                        </div>
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p className="fs-1">Wrong Answer Text</p>
                        <center>
                            <textarea value={wrong} className="form-control" id="inputWrong" name="inputWrong" rows={wrong.split("\n").length} required autoComplete="off" onChange={(e) => { this.setState({ wrong: e.target.value }) }} />
                            <br />
                            <div style={{ position: 'relative', textAlign: "left", marginLeft: '5%' }}>
                                <MediaUpload updateURL={(e) => this.setState({ wrongFile: `${userActions.API.split('/api')[0]}${e}` })} />
                                <div style={{ display: 'inline-block', width: '45%', top: '0px', position: 'absolute' }}>
                                    <center>URL</center>
                                    <input value={wrongFile} className="form-control" id="inputQuestion" name="inputQuestion" autoComplete="off"
                                        placeholder='https://somedomain.com/filename.extention' onChange={(e) => this.setState({ wrongFile: e.target.value })} />
                                </div>
                            </div>
                        </center>
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p className="fs-1">Add Tags</p>
                        <div class="tags-container tag-input-field">
                            <input className="form-control me-auto questionText" autoComplete="off" placeholder='Type your tag here' value={tag} onChange={(e) => this.setState({ tag: e.target.value })} />
                            <button type="button" className="quiz add-tag-btn" 
                                onClick={() => this.addTag()} >
                                Add
                            </button>
                        </div>
                        {this.displayTags()}
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p>Other Options</p>
                        <center>
                            <label className="inline choice align-left" htmlFor="shuffle">
                                <div className="col-2">
                                    Shuffle Choices
                                </div>
                                <div className="col-6">
                                    <input id="shuffle" type="checkbox" className="checkbox-round" autoComplete="off" checked={scrambleChoices}
                                        onChange={(e) => this.setState({ scrambleChoices: e.target.checked })} />
                                </div>
                            </label>
                        </center>
                    </div>

                    <center>
                        <button className="size-lrg less-round dropShadow">Submit</button>
                    </center>
                </form>
            </Fragment>);
        } else { // -------------------------------------------------------------------  Editing Quiz ------------------------------------------------------------------- 
            const { discount, cost, scrambleQuestions, questionsPerQuiz, questionsIds, name, viewingQuestion, failAt, Qattempt, QuestAttempt, RedoIncrese, passing, description } = this.state;
            const { questions } = this.props.session;
            return (<Fragment>
                {viewingQuestion && <div className='question-overlay'>
                    <div className='question-overlay-out' onClick={() => this.setState({ viewingQuestion: "" })}></div>
                    <QuestionPreview question={questions.filter(q => q._id == viewingQuestion)[0]} />
                </div>}

                <form className='creation' onSubmit={this.stop}>
                    <div className="lrg-container contain border border-2 border-black">
                        <p className="blackText">Quiz Name</p>
                        <input value={name} className="form-control" id="name" placeholder='Quiz name' required autoComplete="off" onChange={(e) => this.setState({ name: e.target.value })} />
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p className="blackText">Quiz Questions</p>

                        <div className='flex-form gap'>
                            {this.findQuestions()}
                        </div>

                        <br />

                        <div>
                            <h5 className="blackText search-question">Search for Existing Questions.</h5>
                            <div className='flex-form gap'>
                                <div className=' inline gap choice spc-between'>
                                    <div className='question-search inline gap'>
                                        <div className="me-auto questionText">
                                            <input className="form-control " id="query" autoComplete="off" placeholder='$ to view all' onChange={(e) => this.setState({ query: e.target.value })} />
                                        </div>
                                        <button type="button" className="quiz"
                                            onClick={() => this.addAllFlitered()} >
                                            Add All
                                        </button>
                                    </div>
                                </div>
                                {this.filterQuestions()}
                            </div>
                        </div>
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p className="blackText">Quiz Certificate Description</p>
                        <textarea className="form-control" rows={5} autoComplete="off" value={description} onChange={(e) => this.setState({ description: e.target.value })} />
                    </div>

                    <div className="lrg-container contain border border-2 border-black">
                        <p className="blackText"> Other Options</p>
                        <div className="flex-from gap">
                            <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">Shuffle Questions</div>
                                    <div className="col-2 align-left">
                                        <input type="checkbox" className="checkbox-square" autoComplete="off" checked={scrambleQuestions}
                                            onChange={(e) => this.setState({ scrambleQuestions: e.target.checked })} />
                                    </div>
                                </div>
                            </center>
                            <br/>
                            {/* <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        Cost: <strong>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).format(cost)}</strong>
                                    </div>
                                    <div className="col-5">
                                        <input className="form-control" type="number" min="0" step="any" autoComplete="off" value={cost} onChange={(e) => this.setState({ cost: e.target.value })} />
                                    </div>
                                </div>
                            </center>
                            <br/> */}
                            {/* <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        Discount: <strong>{parseFloat(discount).toFixed(0) + "%"}</strong>
                                    </div>
                                    <div className="col-5">
                                        <input className="form-control" type="number" min="0" max="100" step="any" value={discount} autoComplete="off" onChange={(e) => this.setState({ discount: e.target.value })} />
                                    </div>
                                </div>
                            </center>
                            <br/> */}
                            <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        Quit Quiz after: <strong>{failAt || 'Infinite'} Wrong Answers</strong>
                                    </div>
                                    <div className="col-2 inline gap align-items-center">
                                        <div>
                                            <input type="checkbox" className="checkbox-square" autoComplete="off" checked={failAt}
                                                onChange={(e) => this.setState({ failAt: failAt ? 0 : 2 })} />
                                        </div>
                                        <input className="form-control" type="number" min="0" max="100" step="any" value={failAt || ''} autoComplete="off" onChange={(e) => this.setState({ failAt: parseInt(e.target.value) })} />
                                    </div>
                                </div>
                            </center>
                            <br/>
                            <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        <strong>{Qattempt || 'Infinite'}</strong> Quiz Attempts Per Purchase
                                    </div>
                                    <div className="col-2 inline gap align-items-center">
                                        <div>
                                            <input type="checkbox" className="checkbox-square" autoComplete="off" checked={Qattempt}
                                                onChange={() => this.setState({ Qattempt: Qattempt ? 0 : 1 })} />
                                        </div> 
                                        <input className="form-control" type="number" min="1" max="100" step="any" value={Qattempt || ''} autoComplete="off" onChange={(e) => this.setState({ Qattempt: parseInt(e.target.value) })} />
                                        
                                    </div>
                                </div>
                            </center>
                            <br/>
                            <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        <strong>{QuestAttempt || 'Infinite'}</strong> Question Attempts
                                    </div>
                                    <div className="col-2 inline gap align-items-center">
                                        <div>
                                            <input type="checkbox" className="checkbox-square" autoComplete="off" checked={QuestAttempt}
                                            onChange={() => this.setState({ QuestAttempt: QuestAttempt ? 0 : 2 })} />
                                        </div>           
                                        <input className="form-control" type="number" min="1" max="100" step="any" value={QuestAttempt || ''} autoComplete="off" onChange={(e) => this.setState({ QuestAttempt: parseInt(e.target.value) })} />
                                    </div>
                                    
                                </div>
                            </center>
                            <br/>
                            <center>
                                <div className='inline choice' >
                                    <div className="col-5 align-left">
                                        Passing Quiz Percentage: <strong>{parseFloat(passing).toFixed(0)}%</strong>
                                    </div>
                                    <div className="col-5">
                                        <input className="form-control" type="number" min="0" max="100" step="any" value={passing} autoComplete="off" onChange={(e) => this.setState({ passing: e.target.value })} />
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                    <center>
                        <button className="size-lrg less-round dropShadow" onClick={() => this.editQuiz()}>Submit</button>
                    </center>
                </form>
            </Fragment >);
        }
    }
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    editQuestion: userActions.editQuestion,
    editQuiz: userActions.editQuiz,
    clearAlerts: alertActions.clear,
    alertError: alertActions.error,
};

const connectedEditPage = connect(mapState, actionCreators)(EditPage);
export { connectedEditPage as EditPage };